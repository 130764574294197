import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Menu,Icon,Search} from 'semantic-ui-react'
import {CapitalFirst} from '../../../Funciones/utils'

const TipoMenu=({isMobile})=>{
    const [search, setSearch] = useState();
    const {preciosXFecha,tipoPrecio,searchResult,searchLoading,sidebarOpened}=useSelector(x=>x.mainReducer)
    const dispatch=useDispatch();
    useEffect(()=>{
      if (search!=undefined&&search.trim()!=""&&search.length>2) {
        dispatch({type:'SEARCH',payload:{searchThing:search}})
      }  
    },[search])
    return(
    <Menu pointing secondary>
            {preciosXFecha.length!=1&&preciosXFecha.filter(x=>x.type.split('-').length==1).map((x,i)=>
                    <Menu.Item
                        key={i}
                        name={CapitalFirst(x.type)}
                        active={tipoPrecio === x.type}
                        onClick={()=>{dispatch({type:"SET_TIPO_PRECIO",payload:x.type})}}
                    />
            )} 
    <Menu.Item 
        position='right'>
          {!isMobile&&<Search
          noResultsMessage={'No se encontraron registros'}
          loading={searchLoading}
          aligned={'center'}
          style={{margin:'auto'}}
          size={isMobile?'large':''}
          minCharacters={'3'}
          onSearchChange={(e)=>{setSearch(e.target.value)}}
          results={searchResult}
          value={search}
        /> }   
        </Menu.Item>

        <Menu.Item
          style={{marginBottom:'5px'}}
          name='Actualizar'
          active={false}
          onClick={()=>{dispatch({type:'RELOAD_PRICE_LIST'});
          ;dispatch({type:'RELOAD_RESERVATIONS'});}}
        >
            <Icon size={isMobile?'big':'large'} name={'redo'}></Icon>
        </Menu.Item>
        {isMobile&&<Menu.Item 
            onClick={()=>{dispatch({type:'SET_SIDEBAR',payload:!sidebarOpened})}}
          ><Icon size={'big'} name={'bars'}></Icon></Menu.Item>}

    </Menu>
    )
}

export default TipoMenu