import React from "react"
import {Col,Row,Container } from 'reactstrap';
import moment from "moment"

const SecondPage = ({ingreso:x}) => 
{
    const ingreso={
        'nombreyape':x.customersList.map(x=>x.fullName).join('\n'),
        'mayores':x.customersList.length,
        'menores':"",
        'dni':x.customersList[0].dni??"-",
        'edad':(x.customersList[0].age==0?x.customersList[0].age:"-")??"-",
        'procedencia':x.customersList[0].geo?JSON.parse(x.customersList[0].geo).value:"-",
        'vehiculos':x.vehiclesList.length>0?x.vehiclesList.map(y=>(`${y.brand.toUpperCase()} ${y.plate}`)).toString().replace(/,/g,' | '):"-",
        'lugar':x.roomsList.map(x=>x.number).join(' ').toLowerCase(),
        'Fecha De Ingreso':moment(x.checkinMade).format("DD MMM YYYY hh:mm"),
        'checkinEstimated':moment(x.checkinEstimated),
      }
    return(
        <React.Fragment >
            <div id="printFirma">
            <div class="d-flex flex-column bd-highlight mb-3">
                <div class="d-flex justify-content-center bd-highlight">
                    <div class="p-2 bd-highlight flex-fill" style={{textAlign:'center',backgroundColor:"red",fontSize:'40px'}}>Voucher de reserva</div>
                </div>
                <div class="d-flex flex-row bd-highlight mb-3">
                    <div class="d-flex flex-column bd-highlight mb-3">
                        Fecha de ingreso
                    </div>
                    <div class="d-flex flex-column bd-highlight mb-3">
                        {moment(x.checkinEstimated).format("DD MMM YYYY")}               
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    );  
}
export default SecondPage