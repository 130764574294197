import React,{useMemo} from 'react'
import moment from 'moment'
import {Line} from 'react-chartjs-2';
import {useSelector} from 'react-redux'
import LoadingOverlay from 'react-loading-overlay';


const datasetTemplate= {
    label: 'Personas',
    fill: false,
    data: [20,10,100]
  }
const createChartTemplate=()=>{
return {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: []
};
}
const DynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
 };

const registros = createChartTemplate();

const Mensual=()=>{
    const {statisticsMonth,statisticsByMonthLoading,isMobile}=useSelector(x=>x.mainReducer)
    registros.datasets=
    useMemo(()=>statisticsMonth.length==0?[]:
    Object.keys(statisticsMonth[0]).filter(x=>x!='date').map(x=>({...datasetTemplate,borderColor:DynamicColors(),label:x,data:statisticsMonth.map(y=>y[x]??0)})),[statisticsMonth])
    if (statisticsMonth.length==0) {
        return<></>

    }

    registros.labels=statisticsMonth.map(x=>moment(x.date).format('dd DD'))
    //registros.datasets[0].data=estadisticasGrafico.map(x=>x.registros)
    return(
    <React.Fragment>
           <LoadingOverlay
    active={statisticsByMonthLoading}
    spinner
    text='Actualizando'
    >
    {!isMobile&&<Line data={registros}/>}
    </LoadingOverlay>
    </React.Fragment>
        
    )
}

export default Mensual
