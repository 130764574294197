import React, { useMemo } from "react"
import moment from 'moment'
import {Col,Row,Modal, ModalHeader, ModalBody, } from 'reactstrap';
import {CapitalFirst,InformacionReservas} from "../../../Funciones/utils"
import {connect, useDispatch} from 'react-redux'
import GetCard from '../../../components/dormis/main/getCard'
import { Card,Header, Segment,Icon } from 'semantic-ui-react'

//ACTIONS
import setFecha from '../../../redux/actions/displayFecha'

// export const DisplayFecha = ({fecha,listaReservas,dispatch,mostrarFull}) => {

//    const informacionReservasAux=useMemo(() => InformacionReservas(listaReservas,fecha), [fecha]) 
//    const informacionReservas=useMemo(() =>fecha?{entran:informacionReservasAux.entran.filter(x=>x.state!="cancelada"),
//                               estancias:informacionReservasAux.estancias.filter(x=>x.state!="cancelada"),
//                               salen:informacionReservasAux.salen.filter(x=>x.state!="cancelada")}:[], [fecha])
//    if(!fecha){
//      return null;
//    }
//    return (
//       <Row>

//         <Col xs={12} md={4}>  
          
//           <Header style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
//             <Icon name='sign-in' color={"blue"} circular />
//             <Header.Content>Entradas ({informacionReservas.entran.map(x=>x.roomsList).flat().length})</Header.Content>
//           </Header>
//           {mostrarFull&&
//           <Segment attached>
//             <Card.Group>

//           {informacionReservas.entran.map((x,i)=>(

//                  <GetCard ingreso={x} i={i}></GetCard>
//           ))}
          
        
//            </Card.Group>
//           </Segment>}
//         </Col>

//       <Col xs={12} md={4}>  
//       <Header  style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
//             <Icon name='home' color={"blue"} circular />
//             <Header.Content>Estancias ({informacionReservas.estancias.map(x=>x.roomsList).flat().length})</Header.Content>
//           </Header>
//         {mostrarFull&&
//           <Segment attached>
//           <Card.Group>
//           {informacionReservas.estancias.map((x,i)=>(
//                   <GetCard ingreso={x} i={i}></GetCard>

//           ))}
//         </Card.Group>
//       </Segment>}
//       </Col>

//       <Col xs={12} md={4}>  
//       <Header style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
//             <Icon name='sign-out' color={"blue"} circular />
//             <Header.Content>Salidas ({informacionReservas.salen.map(x=>x.roomsList).flat().length})</Header.Content>
//           </Header>
//         {mostrarFull&&
//           <Segment attached>
//           <Card.Group>
//           {informacionReservas.salen.map((x,i)=>(
//                  <GetCard ingreso={x} i={i}></GetCard>
//           ))}
//         </Card.Group>
//       </Segment>}
//       </Col>
//      </Row>

   
//     )
// }

const LugaresSuperpuestos=({placeChoosed,listaReservas,showModal})=>{
  const dispatch=useDispatch();
  return(
  <React.Fragment> 
   <Modal key={"map"} style={{maxWidth:"700px"}} size={"lg"} isOpen={showModal} toggle={()=>{dispatch({payload:false,type:"SHOW_MAP_MODAL"})}} >
    <ModalHeader style={{borderBottom:"none"}} toggle={()=>{dispatch({payload:false,type:"SHOW_MAP_MODAL"})}}
    >{placeChoosed&&<Header as='h2'>{placeChoosed.show} registros en el lugar "{placeChoosed.number}"</Header>}
    </ModalHeader>
    <ModalBody>
    
    <Card.Group centered={true}>

      {listaReservas.filter(x=>x.roomsList.filter(y=>y&&placeChoosed).find(y=>y.number==placeChoosed.number)).map((x,i)=>(
        //using flex with flex direction row
         <GetCard style={{margin:0}} ingreso={x} i={`map${i}`}></GetCard>
      ))}
  </Card.Group>


  </ModalBody>
  </Modal>
 </React.Fragment>
 )
}

export default connect(state=>({listaReservas:state.mainReducer.listaReservas,showModal:state.mapReducer.showModal,placeChoosed:state.mapReducer.placeChoosed}),null)(LugaresSuperpuestos)

