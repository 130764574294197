import React from 'react'
import moment from 'moment'
import {Col,Input } from 'reactstrap';
import {useSelector,useDispatch} from 'react-redux'
import {  Statistic,Header,Icon } from 'semantic-ui-react'
import {CapitalFirst} from '../../../../Funciones/utils'
import LoadingOverlay from 'react-loading-overlay';

const Estadisticas=()=>{
const dispatch = useDispatch(x=>x)
const {generalStatisticsByDate,statisticsByMonthLoading,statisticsDate} = useSelector(x => x.mainReducer)


const setFecha=(date)=>{
    dispatch({type:'SET_STATISTICS_DATE',payload:date})
}

if(!generalStatisticsByDate){
    return <></>
}
return(
  <React.Fragment>
    <LoadingOverlay
    active={statisticsByMonthLoading}
    spinner
    text='Actualizando'
    >
      <Col xs={12} md={2}>
        <Input type={"date"} value={statisticsDate} onChange={(e)=>{setFecha(e.target.value)}}/>
      </Col>
      <div className={'d-flex justify-content-center flex-wrap'}>
        <div className={"p-3 bd-highlight"}>
            <Icon style={{cursor:'pointer'}} name="arrow left" size={'huge'} onClick={()=>{setFecha(moment(statisticsDate).add(-1,'days').format('YYYY-MM-DD'))}}/>
        </div>
        <div className={"p-3 align-self-center"}>
            <Header as='h1'>{CapitalFirst(moment(statisticsDate).format('dddd DD MMMM'))}</Header>
        </div>
        <div className={"p-3 bd-highlight"}>
            <Icon style={{cursor:'pointer'}} name="arrow right" size={'huge'} onClick={()=>{setFecha(moment(statisticsDate).add(1,'days').format('YYYY-MM-DD'))}}/>
        </div>
      </div>
      <br></br>
      <div className={'d-flex justify-content-center justify-content-around flex-wrap'}>
        {Object.keys(generalStatisticsByDate).filter(x=>x!='date').map((x,i)=>(
            <>
                <div className={"p-3 bd-highlight"}>
                <Statistic>
                    <Statistic.Label>{x}</Statistic.Label>
                    <Statistic.Value>{generalStatisticsByDate[x]}</Statistic.Value>
                </Statistic>
                </div>
            </>
        ))}
        </div>

    </LoadingOverlay>
  </React.Fragment>
    
)
}
export default Estadisticas

