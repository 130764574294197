import React, { useMemo } from "react"
import moment from 'moment'
import {Col,Row,Modal, ModalHeader, ModalBody, } from 'reactstrap';
import {CapitalFirst,InformacionReservas} from "../../../Funciones/utils"
import {connect} from 'react-redux'
import GetCard from './getCard'
import { Card,Header, Segment,Icon } from 'semantic-ui-react'


//ACTIONS
import setFecha from '../../../redux/actions/displayFecha'
import { roomsList } from "../../../graphql/queries";

export const DisplayFecha = ({fecha,listaReservas,dispatch,mostrarFull}) => {

   const informacionReservasAux=useMemo(() => InformacionReservas(listaReservas,fecha), [fecha]) 
   const informacionReservas=useMemo(() =>fecha?{entran:informacionReservasAux.entran.filter(x=>x.state!="cancelada"),
                              estancias:informacionReservasAux.estancias.filter(x=>x.state!="cancelada"),
                              salen:informacionReservasAux.salen.filter(x=>x.state!="cancelada")}:[], [fecha])
   if(!fecha){
     return null;
   }
   return (
      <Row>

        <Col xs={12} md={4}>  
          
          <Header style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
            <Icon name='sign-in' color={"blue"} circular />
            <Header.Content>Entradas ({informacionReservas.entran.map(x=>x.roomsList).flat().length})</Header.Content>
          </Header>
          {mostrarFull&&
          <Segment attached>
            <Card.Group>

          {informacionReservas.entran.map((x,i)=>(

                 <GetCard ingreso={x} i={i}></GetCard>
          ))}
          
        
           </Card.Group>
          </Segment>}
        </Col>

      <Col xs={12} md={4}>  
      <Header  style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
            <Icon name='home' color={"blue"} circular />
            <Header.Content>Estancias ({informacionReservas.estancias.map(x=>x.roomsList).flat().length})</Header.Content>
          </Header>
        {mostrarFull&&
          <Segment attached>
          <Card.Group>
          {informacionReservas.estancias.map((x,i)=>(
                  <GetCard ingreso={x} i={i}></GetCard>

          ))}
        </Card.Group>
      </Segment>}
      </Col>

      <Col xs={12} md={4}>  
      <Header style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
            <Icon name='sign-out' color={"blue"} circular />
            <Header.Content>Salidas ({informacionReservas.salen.map(x=>x.roomsList).flat().length})</Header.Content>
          </Header>
        {mostrarFull&&
          <Segment attached>
          <Card.Group>
          {informacionReservas.salen.map((x,i)=>(
                 <GetCard ingreso={x} i={i}></GetCard>
          ))}
        </Card.Group>
      </Segment>}
      </Col>
     </Row>

   
    )
}

const ModalDisplayFecha=({fecha,listaReservas,dispatch,mostrarFull})=>{
  return(
  <Modal style={{maxWidth:"1000px"}} size={"lg"} isOpen={fecha!=null} toggle={()=>{dispatch(setFecha(null));}} >
  <ModalHeader style={{borderBottom:"none"}} toggle={()=>{dispatch(setFecha(null));}}
  ><Header as='h2'>{CapitalFirst(moment(fecha).format("dddd DD/MM/YYYY"))}</Header>
  </ModalHeader>
  <ModalBody>
    <DisplayFecha fecha={fecha} listaReservas={listaReservas} dispatch={dispatch} mostrarFull={mostrarFull}/>
</ModalBody>
</Modal>)
}

export default connect(state=>({listaReservas:state.mainReducer.listaReservas}),null)(ModalDisplayFecha)

