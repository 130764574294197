import React,{useState,useEffect} from "react"
import LoadingOverlay from 'react-loading-overlay';
import {Modal, ModalHeader, ModalBody, Container,Col,Row} from 'reactstrap';
import {connect} from 'react-redux'
import moment from 'moment'
import StepWizard from 'react-step-wizard';
import Caja from './caja'
import Reserva from './reserva'
import {CalcularPrecios} from './calcularPrecios'
import Facturacion from './facturacion'
import { Menu } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Icon,Form, TextArea,Label,Dropdown } from 'semantic-ui-react'
import {CambiarEstadoReserva,ElegirLabel,CambiarEstadoCheckinReserva,ElegirOtaLabel,GeneratePrintTemplate,printPOS,connectPrinter,chooseCustomer} from '../../../Funciones/utils'
import { Button} from 'semantic-ui-react'
import Firma from './firma'
import printJS from 'print-js'



//Actions
import setModal from '../../../redux/actions/setModalReserva'

const ReiniciarModal=(dispatch,setActiveItem,stepWizard)=>{
  setActiveItem("pagos")
  stepWizard.goToStep(1)
  dispatch(setModal(false))
}

export const ModalAux = ({billSession,togglePrinter,diaPlaya,openModal,dispatch,reserva,isMobile,isConnecting,isPrinting,isConnected}) => {
  const [nuevaReserva,setNuevaReserva]=useState(false);
  const [stepWizard,setStepWizard]=useState({});
  const [notas,setNotas]=useState("");
  const [firmaVisible,setFirmaVisible]=useState(false);
  const [loading,setLoading]=useState(false);
  const [activeItem,setActiveItem]=useState("reserva");
  const [openPOS,setOpenPOS]=useState(false);

  useEffect(()=>{
    if (reserva.nuevaReserva){
      setNuevaReserva(true)
    }
    else{
      setNuevaReserva(reserva.nuevaReserva)
      setNotas(reserva.description)
      if (!openModal) {
        setActiveItem(reserva.customersList?.length==0?"reserva":"pagos")

      }
    }
  
  },[reserva,reserva.nuevaReserva])
  useEffect(()=>{
    if (firmaVisible) {
      const serviceListNotCanceled=reserva.servicesList.filter(x=>!x.canceled);
      printJS({
        targetStyle:['text-align','font-size','display','justify-content'],
        type:'raw-html',printable: GeneratePrintTemplate(
        "",
        reserva.customersList,
        reserva.checkinEstimated,
        reserva.checkoutEstimated,
        serviceListNotCanceled.map(y=>y.paid).reduce((a,b) => a + parseInt(b), 0),
        serviceListNotCanceled.filter(x=>!x.canceled).map(y=>y.cost).reduce((a,b) => a + parseInt(b), 0)-serviceListNotCanceled.map(y=>y.paid).reduce((a,b) => a + parseInt(b), 0)
      )})
      setFirmaVisible(false)
    }
  },[firmaVisible])
  const goToPayment=()=>{setActiveItem("pagos");stepWizard.goToStep(1);document.querySelector("div.modal.fade.show").scrollTo({ top: 0, behavior: 'smooth' })}
  return (
    <Modal style={{maxWidth:"1200px"}} size={"lg"} isOpen={openModal} toggle={()=>{if(reserva.calcularPrecio||!nuevaReserva||window.confirm('¿Salir sin guardar cambios?')){ReiniciarModal(dispatch,setActiveItem,stepWizard);}}} >
    <LoadingOverlay
    active={loading}
    spinner
    text='Agregando'
    >
    <ModalHeader style={{borderBottom:"none"}} toggle={()=>{if(reserva.calcularPrecio||!nuevaReserva||window.confirm('¿Salir sin guardar cambios?')){ReiniciarModal(dispatch,setActiveItem,stepWizard);}}}
    >{reserva.calcularPrecio?"Calcular precios":!nuevaReserva?`Cuenta de ${reserva.customersList?.length>0?`${reserva.customersList[0].fullName}`:""}`:`Nuevo Registro`}
    {!nuevaReserva&&ElegirOtaLabel(reserva.way)}
          <br></br>
    {!nuevaReserva&&ElegirLabel(reserva.state,reserva.nightsDue)}
    </ModalHeader>
    {!nuevaReserva&&firmaVisible&&<Firma ingreso={reserva}/>}
    <ModalBody>
    {!reserva.calcularPrecio&&<Menu tabular={!isMobile} stackable>
    {!nuevaReserva&&<Menu.Item
          active={activeItem === 'pagos'}
          onClick={()=>{setActiveItem("pagos");stepWizard.goToStep(1)}}
        >Pagos<Icon size='large' style={{marginLeft:"5px"}} name='money bill alternate'/></Menu.Item>}
      <Menu.Item
          active={activeItem === 'reserva'}
          onClick={()=>{setActiveItem("reserva");stepWizard.goToStep(2)}}
        >Datos<Icon size='large' style={{marginLeft:"5px"}} name='address book'/></Menu.Item>
      {!nuevaReserva&&<Menu.Item
          active={activeItem === 'notas'}
          onClick={()=>{setActiveItem("notas");stepWizard.goToStep(3)}}
        >Notas<Icon size='large' style={{marginLeft:"5px"}} name='edit'/>{reserva.description&&<Label color='red'>1</Label>}
        </Menu.Item>}
      {!nuevaReserva&&billSession.toggleBilling&&<Menu.Item
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'facturacion'}
          onClick={()=>{setActiveItem("facturacion");stepWizard.goToStep(4)}}
        >Facturacion<Icon size='large' style={{marginLeft:"5px"}} name='file alternate'/></Menu.Item>}
      {!nuevaReserva&&<Menu.Item
          className={"custombutton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          position='right'
          onClick={()=>{setFirmaVisible(true);}}
        >Imprimir<Icon size='large' style={{marginLeft:"5px"}} name='print'/></Menu.Item>}
      {!nuevaReserva&&togglePrinter&&<Menu.Item
          className={isConnected?"custombutton":"POSDisabled"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          onClick={()=>{
            setOpenPOS(!openPOS)
          }}
        >
            <Dropdown loading={isConnecting||isPrinting} text="Comandera" simple open={openPOS} openOnFocus={false}>
              <Dropdown.Menu>
                <Dropdown.Item disabled={!isConnected} onClick={()=>{
                    printPOS(reserva,"checkin")
                }}>
                  Checkin
                  <Icon style={{float:"left"}} name="sign-in" />
                </Dropdown.Item>
                <Dropdown.Item disabled={!isConnected}
                  onClick={()=>{
                    printPOS(reserva,"checkout")
                  }}>
                  Checkout
                  <Icon style={{float:"left"}} name="sign-in" />
                </Dropdown.Item>
                <Dropdown.Divider />
                {/* Re connect icon */}
                <Dropdown.Item disabled={isConnected}
                  onClick={()=>{
                    connectPrinter()
                  }}>
                  <Icon name='redo' />
                  <span className='text'>Reconectar</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

        </Menu.Item>}
      {!nuevaReserva&&<Menu.Item
          className={"custombutton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'duplicar'}
          onClick={()=>{if (window.confirm(`¿Seguro que quieres empezar un nuevo registro a partir de los datos de ${reserva.customersList[0].fullName}?`)) {
            dispatch({type:'DUPLICATE_RESERVATION',payload:reserva})
            stepWizard.goToStep(1)
          }}}
        >Duplicar<Icon size='large' style={{marginLeft:"5px"}} name='copy outline'/></Menu.Item>}
      {!nuevaReserva&&reserva.state=="checkin"&&<Menu.Item
          className={"checkoutButton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'Checkout'}
          onClick={()=>{if(window.confirm("¿Concomandera checkout?"))CambiarEstadoCheckinReserva(reserva.reservationId,'checkout',setLoading,dispatch)}}
        >Checkout<Icon size='large' style={{marginLeft:"5px"}} name='sign-out'/></Menu.Item>}
      {!nuevaReserva&&reserva.state=="confirmada"&&<Menu.Item
          className={"checkinButton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'Checkin'}
          onClick={()=>{if(window.confirm("¿Concomandera checkin?"))CambiarEstadoCheckinReserva(reserva.reservationId,'checkin',setLoading,dispatch)}}
        >Checkin<Icon size='large' style={{marginLeft:"5px"}} name='sign-in'/></Menu.Item>}
    </Menu>}
  {/* remove slide effect stepWizard */}
      <StepWizard instance={(e)=>setStepWizard(e)} transitions={{}} >
          {reserva.calcularPrecio&&<CalcularPrecios/>}
          {!nuevaReserva&&<Caja   goToFacturacion={()=>{setActiveItem("facturacion");stepWizard.goToStep(4)}} nombreCuenta={reserva?.customersList?.length>0?reserva.customersList[0].fullName:''} reserva={reserva} ReiniciarModal={()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard)}}/>}
          <Reserva  reserva={reserva} nuevaReserva={nuevaReserva} goToPayment={diaPlaya?()=>{dispatch({type:'SET_MODAL_RESERVA',payload:false})}:goToPayment}/> 
          {!nuevaReserva&& 
          <Form>
            <TextArea value={notas} onChange={(e)=>{setNotas(e.target.value)}} placeholder='Escribir notas..'/>
            <Container>
            <br></br>
            <Row>
              <Col style={{display: "contents"}}>
                <Button size={isMobile?'massive':'big'} color='teal' fluid onClick={()=>{setLoading(true);reserva.checkoutEstimated=moment(reserva.checkoutEstimated).format();reserva.description=notas;CambiarEstadoReserva(reserva,setLoading,()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard);},dispatch)}}>Guardar</Button></Col>
            </Row>
         </Container>
         </Form>}
         {!nuevaReserva&&billSession.toggleBilling&&<Facturacion />}
      </StepWizard>
    </ModalBody>
    </LoadingOverlay>
  </Modal>
   
    )
}



export default connect(state=>({isConnecting:state.printerReducer?.printer?.isConnecting,isPrinting:state.printerReducer?.printer?.isPrinting,isConnected:state.printerReducer?.printer?.isConnected,togglePrinter:state.printerReducer.togglePrinter,billSession:state.billReducer,diaPlaya:state.mainReducer.diaPlaya,withoutPlace:state.mainReducer.withoutPlace,checkoutTime:state.mainReducer.checkoutTime,preReserva:state.mainReducer.preReserva,openModal:state.mainReducer.modal,reserva:state.mainReducer.reserva,isMobile:state.mainReducer.isMobile}),null)(ModalAux)

