import React,{useState,useCallback} from 'react'
import moment from 'moment'
import {Input } from 'reactstrap';
import {useDispatch} from 'react-redux'
import {  Statistic,Header,Icon,Button } from 'semantic-ui-react'
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux'


const RangoFechas=({tipoPrecio})=>{
const [fechaDesde,setFechaDesde]=useState(moment().format('YYYY-MM-DD'))
const [fechaHasta,setFechaHasta]=useState(moment().format('YYYY-MM-DD'))
const [loading,setLoading]=useState(false)
const [estadisticas,setEstadisticas]=useState([])
const dispatch=useDispatch(x=>x)
const BuscarEstadisticas=useCallback(async ()=>{
    if(moment(fechaHasta).diff(fechaDesde,'minutes')>=0)
    {
        dispatch({type:'RELOAD_PAYMENT_LIST_STATISTICS',from:fechaDesde,to:fechaHasta})
    }
    else{
        alert('La fecha de entrada no puede ser menor a la de salida')
    }

},[fechaHasta,fechaDesde,tipoPrecio])

return(
  <React.Fragment>
    <LoadingOverlay
    active={loading}
    spinner
    text='Actualizando'
    >
     <div className={'d-flex justify-content-center flex-wrap'}>

        <div className={"p-3 align-self-center"}>
            <Header as='h1'>Rango de fechas</Header>
        </div>

      </div>
    <div className={'d-flex justify-content-center flex-wrap'}>
        <div className={"p-3 bd-highlight"}>
            <Input type={"date"} value={fechaDesde} onChange={(e)=>{setFechaDesde(e.target.value)}}/>
        </div>
        <div className={"p-3 bd-highlight"}>
            <Input type={"date"} value={fechaHasta} onChange={(e)=>{setFechaHasta(e.target.value)}}/>
        </div>
        <div className={"p-3 bd-highlight"}>
            <Button onClick={BuscarEstadisticas}>Buscar</Button>
        </div>
    </div>

      <br></br>
      <div className={'d-flex justify-content-center justify-content-around flex-wrap'}>
        {Object.keys(estadisticas).filter(x=>x!='date').map((x,i)=>(
            <>
                <div className={"p-3 bd-highlight"}>
                <Statistic>
                    <Statistic.Label>{x}</Statistic.Label>
                    <Statistic.Value>{estadisticas[x]}</Statistic.Value>
                </Statistic>
                </div>
            </>
        ))}
        </div>

    </LoadingOverlay>
  </React.Fragment>
    
)
}
export default connect(state=>({tipoPrecio:state.mainReducer.tipoPrecio}),null)(RangoFechas)

