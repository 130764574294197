import React, { useState, useEffect } from "react"
import moment from 'moment'
import { Col, Row, Input, InputGroupText, InputGroup, InputGroupAddon, Container, Button as ButtonR } from 'reactstrap';
import { ListaFechas } from "../../../Funciones/utils"
import { connect } from 'react-redux'
import { Button, Input as SemanticInput, Divider, Icon, Confirm } from 'semantic-ui-react'
import { Collapse } from 'react-collapse';
import { useSelector } from 'react-redux'

export const Precios = ({ setPrecio, fechaInicio, fechaFin, setDescripcion, setComponentes, componentes, noches }) => {
    const { isMobile, preciosXFecha, tipoPrecio } = useSelector(x => x.mainReducer)
    useEffect(() => {
        const listaFechas = ListaFechas(fechaInicio, moment(fechaFin).add(noches==0?0:-1, 'days'))
        const keys = Object.keys(componentes).sort()
        const precio = listaFechas.reduce((acom, fecha) => acom + keys.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type == tipoPrecio).precios.find(x => x.name == comp)?.list.find(x => x.fecha == fecha)?.precio ?? 0) * componentes[comp]), 0), 0)
        setPrecio(precio)
        setDescripcion(keys.reduce((acom, current) => acom + (componentes[current] > 0 ? `${/^\d+$/.test(current[0])?``:`${componentes[current]} `}${current} ` : ""), ""))
    }, [componentes, noches, fechaInicio])
    const componentesAux = JSON.parse(JSON.stringify(componentes));
    return (
        <React.Fragment>
            <Container>
                <Row>
                    {Object.keys(componentes).sort().map((x, i) => (
                        <Col key={i} xs={12} md={3} style={{ backgroundColor: componentesAux[x] != 0 ? '#00b5ad' : '#fff', borderRadius: '5%',border:'2px solid white' }}>{
                            <React.Fragment>
                                <Col xs={12} style={{ textAlign: 'center', size: '16px', fontWeight: 'bold', color: componentesAux[x] != 0 ? '#fff' : 'black' }}>{x.toUpperCase()}<br />${preciosXFecha.find(y => y.type == tipoPrecio).precios.find(y => y.name == x)?.list.find(y => y.fecha == fechaInicio)?.precio ?? 0}</Col>
                                <Col xs={12}>
                                    <div className="d-flex justify-content-center">
                                        <SemanticInput placeholder='Cantidad'>
                                            <Icon onClick={() => { if (componentesAux[x] != 0) { componentesAux[x]--; setComponentes(componentesAux); } }} name='minus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginRight: isMobile ? '20px' : '10px', color: componentesAux[x] != 0 ? '#fff' : 'black' }} link position={'left'} />
                                            <input style={{ marginBottom: '10px', textAlign: 'center', width: '100px', fontSize: '16px' }} value={componentesAux[x]} onChange={(e) => { if (e.target.value > 0) { componentesAux[x] = e.target.value; setComponentes(componentesAux); } }} />
                                            <Icon onClick={() => { componentesAux[x]++; setComponentes(componentesAux); }} name='plus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginLeft: isMobile ? '20px' : '10px', color: componentesAux[x] != 0 ? '#fff' : 'black' }} link />
                                        </SemanticInput>
                                    </div>
                                </Col>
                            </React.Fragment>
                        }
                        </Col>
                    ))}
                </Row>
            </Container>
        </React.Fragment>
    )
}

export const PreciosComponent = ({vistaCompleta,setHora,hora,listaHorarios,modificarFechaIngreso, fechaIngreso,setFechaIngreso,componentes,setComponentes,reserva,costo, setCosto,setFechaSalida,servicesList,setServicio,ingreso, precios, isMobile, calcularPrecio }) => {
    const [tipo, setTipo] = useState("noches")
    const [precio, setPrecio] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [descuento, setDescuento] = useState(0)
    const [descripcion, setDescripcion] = useState("")
    const [descripcionPrecios, setDescripcionPrecios] = useState("")
    const [hasDiscount, setHasDiscount] = useState(false);
    const [abrirComponentes, setAbrirComponentes] = useState(true);

    const serviciosLimpios=servicesList.filter(x =>!x.canceled);
    const primerPago=serviciosLimpios.length == 0||
    (serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso");
    useEffect(() => {
        if (primerPago) {
            setQuantity(ingreso.nights)
            setTipo('noches')
            setAbrirComponentes(true);
        }
        else if (isMobile) {
            //setFechaSalidaAnterior(ingreso.checkoutEstimated)
            setAbrirComponentes(false);
        }
        setFechaIngreso(moment(ingreso.checkinEstimated).format("YYYY-MM-DD"))
    }, [reserva])

    useEffect(() => {
        setCosto(parseInt(precio * (hasDiscount ? (1 - (descuento / 100)) : 1)))
    }, [quantity,precio])
    

    useEffect(() => {
        if (quantity < 0) {
            //alert("Las noches tienen que ser positivas")
            setQuantity(0)
        }
        if (tipo == "noches" || tipo == "") {
            setDescripcion(`${quantity==0?'Pasar el dia':` ${quantity} ${(quantity == 1) ? "noche" : "noches"}`} ${descripcionPrecios}${descuento != 0 ? `[${descuento}% Desc]` : ""}`)
        }
        //alert('cambio ingreso')
    }, [quantity, reserva, descripcionPrecios, descuento])

    useEffect(() => {
        if (costo < 0) {
            //alert("El importe tiene que ser positivo")
            setCosto(0)
        }
    }, [costo])

    useEffect(() => {
        if (calcularPrecio) {
            setQuantity(GetNoches(moment(fechaIngreso).add(1, 'days').format('YYYY-MM-DD')))
        }
    }, [reserva, fechaIngreso])

    useEffect(() => {
        setServicio({ cost: costo, quantity, name: tipo, description: descripcion, components: JSON.stringify(componentes) ,paymentsList:[{amount:costo,method:'efectivo',isRefund:false,date:moment().format()}]})
    },[costo,quantity,descripcion,componentes])

    useEffect(() => {
        setFechaSalida(moment(primerPago ? fechaIngreso : ingreso.checkoutEstimated).add(quantity, 'days').format('YYYY-MM-DD'))
    },[quantity,fechaIngreso])

    const fSalida = moment(primerPago ? fechaIngreso : ingreso.checkoutEstimated).add(quantity, 'days').format('YYYY-MM-DD');
    const deltaEntrada = moment(primerPago ? fechaIngreso : ingreso.checkoutEstimated).format('YYYY-MM-DD');
    const GetNoches = (fSalida) =>moment(fSalida).diff(moment(deltaEntrada).format('YYYY-MM-DD'), "days")

    return (
        <React.Fragment>
            <Collapse isOpened={abrirComponentes}>
                {componentes && <Precios fechaInicio={deltaEntrada} noches={quantity} fechaFin={fSalida} ingreso={ingreso} precios={precios} setPrecio={setPrecio} setDescripcion={setDescripcionPrecios} setComponentes={setComponentes}
                    componentes={componentes} />}
            </Collapse>
            
            {abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle up' size='small' /></Divider>}
            {!abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle down' size='small' /></Divider>}
            <Row>
                {vistaCompleta&&<Col xs={12} md={{ offset: 1, size: 3 }}>
                    <h3 style={{ textAlign: 'center' }}>{tipo == 'noches' ? 'Agregar noches' : 'Cantidad'}</h3>
                    <Col xs={12}>
                        <div className="d-flex justify-content-center">
                            <SemanticInput placeholder='Cantidad'>
                                <Icon onClick={() => {
                                    if (quantity > 0) {
                                        setQuantity(quantity - 1)
                                    }
                                }} name='minus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginRight: isMobile ? '20px' : '10px', color: "black" }} link position={'left'} />
                                <input type="number" pattern="[0-9]*" style={{ marginBottom: '10px', textAlign: 'center', width: '120px', fontWeight: '800', fontSize: "18px" }} value={quantity} onChange={(e) => { setQuantity(e.target.value) }} />
                                <Icon onClick={() => { setQuantity(quantity + 1) }} name='plus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginLeft: isMobile ? '20px' : '10px', color: "black" }} link />
                            </SemanticInput>
                        </div>
                    </Col>

                </Col>}
                <Col xs={12} md={4} >
                    <h3>Descripcion</h3><Input type="textarea" value={descripcion} onChange={(e) => { setDescripcion(e.target.value) }}
                    />
                </Col>

                <Col xs={12} md={4}>
                    <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Col xs={5}> <h3>Valor</h3></Col>
                        {<Col xs={7}> <h3 style={{ float: "right" }}>${parseInt((precio * (hasDiscount ? (1 - (descuento / 100)) : 1) / (quantity == 0 ? 1 : quantity)))} x noche</h3></Col>}
                    </Row>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input type="number" pattern="[0-9]*" style={{ fontSize: "26px", fontWeight: 800 }} value={costo} onChange={(e) => {
                            if (hasDiscount) {
                                const descuent = 100 - Math.ceil(100 * e.target.value / (precio));
                                setDescuento(descuent < 0 ? 0 : descuent);
                            }
                            setCosto(e.target.value)
                        }
                        } />
                        <InputGroupAddon addonType="append">
                            <ButtonR 
                                id={"buttonDisc"} style={{ float: "right" }} onClick={() => {
                                    setHasDiscount(!hasDiscount)
                                    const num = descuento == 0 ? 10 : 0;
                                    setDescuento(num);
                                    const descuent = num > 100 ? 100 : num;
                                    setCosto(parseInt(precio * (1 - (descuent / 100))))
                                }} color="info" size={"lg"}
                            >%</ButtonR>
                        </InputGroupAddon>
                        {descuento != 0 &&
                            <Input type="number" pattern="[0-9]*" style={{ fontSize: "26px", fontWeight: 800 }} value={descuento} onChange={(e) => {
                                const descuent = e.target.value > 100 ? 100 : e.target.value;
                                setCosto(parseInt(precio * (1 - (descuent / 100))))
                                setDescuento(descuent)
                            }
                            } />
                        }
                    </InputGroup>
                </Col>
            </Row>
            <br></br>
            <Row>
                {vistaCompleta&&<Col xs={12} md={{ size: 3, offset: hora?2:3 }}> <h3>Fecha entrada</h3><Input disabled={!calcularPrecio&&!modificarFechaIngreso} type="date" value={deltaEntrada} onChange={(e) => {setFechaSalida(moment(e.target.value).add(1, 'days').format('YYYY-MM-DD')); setFechaIngreso(e.target.value)}}></Input></Col>}
                {vistaCompleta&&<Col xs={12} md={{ size: 3 }}> <h3>Fecha salida</h3><Input type="date" value={fSalida} onChange={(e) => {
                    setQuantity(GetNoches(e.target.value))
                }}></Input>
                </Col>}
                {hora&&<Col xs={12} md={{ size: 2}}> <h3>Hora de salida</h3><Input type="select" value={hora} onChange={(e) => setHora(e.target.value)}>{listaHorarios.map(x => <option>{x}</option>)}</Input></Col>}
            </Row>
        </React.Fragment>

    )
}



export default connect(state => ({reserva:state.reserva,isAdmin: state.isAdmin, tipoPrecio: state.tipoPrecio, session: state.session, listaDeptos: state.listaDeptos, listaReservas: state.listaReservas, refrescarDispoYPrecios: state.refrescarDispoYPrecios, precios: state.precios, isMobile: state.isMobile }), null)(PreciosComponent)

