import React, {Component} from 'react';
import {DayPilot, DayPilotScheduler} from "daypilot-pro-react";
import {connect} from 'react-redux'
import moment from 'moment'
import {ElegirColor} from '../../../Funciones/utils'
import {RefrescarPrecioYDispo} from '../../../Funciones/utils'
import { Icon} from 'semantic-ui-react'

//ACTIONS
import setReserva from '../../../redux/actions/setReserva'
import setModal from '../../../redux/actions/setModalReserva'
import setFecha from '../../../redux/actions/displayFecha'
import { reservationList } from '../../../graphql/queries';


const BuscarReserva=(listaReservas,listaReservasAux,index)=>{
  const reserva=listaReservas.find(x=>x.reservationId==listaReservasAux[index].reservationId)
  return reserva
}

class Scheduler extends Component {

    constructor(props) {
        super(props);
        this.state = {
          dayNames:true,
          locale: "es-es",
          startDate: moment().format(),
          days: 31,
          scale: "Day",
          timeHeaders: [
                { groupBy: "Month"},
                { groupBy: "Day", format: "ddd d"}
            ],
            cellWidthSpec: "Auto",
            cellHeightSpec: "Auto",
            cellWidth: 100,
            eventHeight:40,
            treeEnabled : true,
            treePreventParentUsage :true,
            resources: props.listaDeptos.map(x=>({name:x.number,id:x.number})).sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)),
            events: []
        };
    }
  

    cellWidthChange(ev) {
        var checked = ev.target.checked;
        this.setState({
            cellWidthSpec: checked ? "Auto" : "Fixed"
        });
    }
    componentDidMount(){
      const hooked=window.alert
      window.alert=(e)=>{if(e.toLocaleLowerCase().split('daypilot').length==1){hooked(e)}}
    }
    
    render() {
      var {checkoutTime,dispatch,listaReservas,withoutPlace,fechaInicio,cantidadDias,listaDeptos,disable,isMobile}=this.props;
      if(listaReservas==undefined){
        return <React.Fragment></React.Fragment>
      }
      //alert(JSON.stringify(listaReservas))
      const listaReservasAux=JSON.parse(JSON.stringify(listaReservas)).map((x,o)=>(x.roomsList.length==0&&withoutPlace)?[{...x,number:"sn"}]:x.roomsList.map((y,i)=>({...x,number:y.number}))).flat()
      //Agrupamos los lugares para mostrarlos
      let obj=[]
      for (var item of listaDeptos){
        if (obj.find(x=>x.id==item.sector)==undefined){
          obj=[...obj,{id:item.sector,name:`Sector ${item.sector}`,expanded: true,}]
        }
      }
      for (var item of obj){
        item.children=listaDeptos.filter(x=>x.sector==item.id).map(x=>({name:x.number,id:x.number})).sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))
      }

      //Si el camping tiene un solo sector
      if (obj.length==1) {
        obj=listaDeptos.map(x=>({name:x.number,id:x.number})).sort((a, b) => parseInt(a.name) < parseInt(b.name) ? -1 : (parseInt(a.name) > parseInt(b.name) ? 1 : 0))
      }
      if(withoutPlace){
        obj=[{name:'Sin lugar',id:'sn'},...obj]
      }
      const fechaInicioAux=!fechaInicio?moment().add(-3,"hours").format():moment(fechaInicio).format();
  
      const config={
          dayNames:true,
          locale: "es-es",
            startDate: fechaInicioAux,
            days: cantidadDias,
            scale: "Day",
            heightSpec:"Max",
            height : 1000,
            treeEnabled : true,
            treePreventParentUsage :true,
            timeHeaders: [
                { groupBy: "Month"},
                { groupBy: "Day", format: "ddd d"}
            ],
            cellWidth:100,
            eventHeight:30,//60,
            allowEventOverlap: false,
            onBeforeEventDomAdd: args => {
            args.element = <div>
              {args.e.data.text}
                <div style={{position: "absolute", right: isMobile?21:5, top: "9px", width: "17px", height: "17px"}}
                     /*onClick={() => this.deleteEvent(args.e)}*/><Icon name={`${(args.e.data.toolTip&&args.e.data.toolTip.trim()!="")?"edit":""}`}/></div>
              </div>;
            },   
            resources: obj,
            events: listaReservasAux.length==0?[]:listaReservasAux.map((x,o)=>({
              id: o, 
              text: isMobile&&moment(x.checkoutEstimated).diff(moment(fechaInicioAux),"days")==0?x.customersList[0].fullName.split(" ")[0]:x.customersList[0].fullName, 
              payed:x.percentPayed,
              toolTip: x.description,
              start: moment(x.checkinEstimated).format('YYYY-MM-DD'), 
              end: moment(x.checkoutEstimated).format('YYYY-MM-DD'), 
              resource: x.number, 
              barColor: ElegirColor(x.state,x.isDeudor), 
              barBackColor: ElegirColor(x.state,x.isDeudor)}))
        };
        return (
        <React.Fragment>
                <DayPilotScheduler
                  {...config}
                  onEventMoved={args => {
                    /*if (!disable){
                      let checkinEstimated=moment(args.newStart.value);
                      let corroborarFecha=checkinEstimated.diff(args.e.calendar.startDate.value,'days')
                      const rVieja={start:args.e.cache.start.value,end:args.e.cache.end.value,depto:args.e.cache.resource}
                      const rNueva={start:args.e.data.start.value,end:args.e.data.end.value,depto:args.e.data.resource}
                      
                      if (CambioReserva(rVieja,rNueva)&&
                        corroborarFecha>-1&&
                        window.confirm(`Seguro que quieres modificar la estadia de ${args.e.data.text}?`)) {
                       
                          
                          let checkoutEstimated=moment(args.newEnd.value).format("YYYY-MM-DD");
                          let ingresoAux=BuscarReserva(listaReservas,listaReservasAux,args.e.data.id);
                          let { hours } = moment(ingresoAux.checkinEstimated).toObject();
                          console.log(hours)
                          checkoutEstimated=moment(args.newEnd.value).set({hour:hours}).format();
                          const roomsList=[...ingresoAux.roomsList.filter(x=>x.room!=rVieja.depto),listaDeptos.find(x=>x.room==args.newResource)]
                          const ingreso={...ingresoAux,checkinEstimated,checkoutEstimated,roomsList};
    
                          const onComplete=()=>{this.scheduler.message("Reserva Movida: " + args.e.data.text);}
                          CambiarEstadoReserva(ingreso,setLoading,onComplete,dispatch,null)
                          this.scheduler.clearSelection()
                        
                      } 
                      else{
                        if  (corroborarFecha<0){
                          alert("La fecha de entrada de la reserva tiene que estar dentro del calendario visible para ser movida")
                        }
                        RefrescarPrecioYDispo(dispatch)
                      }
                 
                    }
                  else{
                    alert("Solo se puede modificar una reserva en la seccion de confirmadas")
                    window.location.reload(false);
                  }*/
                  alert("Accion no permitida")
                  RefrescarPrecioYDispo(dispatch)
                  }}
                  bubble={null}
                  onBeforeEventRender={ args => {
                  
                    var paid = args.e.payed;
                    var paidColor = "#aaaaaa";
                    args.data.areas = [
                      /*{ bottom: 10, right: isMobile?21:4, html: "<div style='color:" + paidColor + "; font-size: 10pt;'>Pagado: " + paid + "%</div>", v: "Visible"},*/
                      { left: 4, bottom: 4, right: 4, height: 2, html: "<div style='background-color:black; height: 100%; width:" + paid + "%'></div>", v: "Visible" },
                  ];
                  if (isMobile){
                    args.data.areas=[...args.data.areas,
                      {right:2, top:6, bottom:2, width: 20, backColor: "lightblue", html: "&gt;", action:"ResizeEnd"},
                    
                    ]
                    
                  }
                }}
                  onEventResized={args => {
                    /*let checkinEstimated=moment(args.newStart.value);
                    let corroborarFecha=1
                    const rVieja={start:args.e.cache.start.value,end:args.e.cache.end.value,depto:args.e.cache.resource}
                    const rNueva={start:args.e.data.start.value,end:args.e.data.end.value,depto:args.e.data.resource}
                    
                    if (!disable){
                      if (CambioReserva(rVieja,rNueva)&&
                          corroborarFecha>-1&&
                        window.confirm(`Seguro que quieres modificar la estadia de ${args.e.data.text}?`)) {
                        checkinEstimated=checkinEstimated.format("YYYY-MM-DD");
                        const checkoutEstimated=moment(args.newEnd.value).format("YYYY-MM-DD");
                        const reserva=BuscarReserva(listaReservas,listaReservasAux,args.e.data.id);
                        const habitacionesDisponibles=HabitacionesDisponibles(checkinEstimated,checkoutEstimated,listaReservas.filter(x=>reserva.reservationId!=x.reservationId))
                        const puedeReservar=PuedeReservar(checkinEstimated,checkoutEstimated,reserva)
                        if (!puedeReservar.resultado) {
                          alert(`Error: El lugar ${puedeReservar.habitacion.room} no esta disponible para las fechas elegidas`)
                          RefrescarPrecioYDispo(dispatch)
                        }
                        else{
                         
                              const ingreso={...reserva,checkinEstimated,checkoutEstimated};
                                const onComplete=()=>{                      
                                  dispatch(setReserva(reserva));
                                  dispatch(setModal(true))}
                                CambiarEstadoReserva(ingreso,setLoading,onComplete,dispatch,null)    
                                this.scheduler.clearSelection()
                              }  
                      }
                        else{
                          if  (corroborarFecha<0){
                            alert("La fecha de entrada de la reserva tiene que estar dentro del calendario visible")
                          }
                          RefrescarPrecioYDispo(dispatch)
                        }
                      }
                    else{
                      alert("Solo se puede modificar una reserva en la seccion de confirmadas")
                      window.location.reload(false);
                    }*/
                    alert("Accion no permitida")
                    RefrescarPrecioYDispo(dispatch)
                  }}
                  onBeforeCellRender = {function(args) {
                    if (args.cell.start.getDatePart().getTime() === new DayPilot.Date().getDatePart().getTime()) {
                      args.cell.backColor = "#fff8b7";
                    
                  }
                  }}
                  onBeforeTimeHeaderRender = {function(args) {
                    args.header.areas = [ {left: 0, top: 0, right: 0, bottom: 0, v: "Hover", action: "JavaScript", js: function(e) { dispatch(setFecha(e.start.value))} } ];
                }}
                  onEventClick={  
                    
                    ars=>{
                      dispatch(setReserva(BuscarReserva(listaReservas,listaReservasAux,ars.e.data.id)));
                      dispatch(setModal(true))
                      this.scheduler.clearSelection()
                      }
                      
                  }
                  onTimeRangeSelected={args => {
                    if (!disable){
                    const checkoutEstimated= moment(args.end.value).add(checkoutTime-3,'hours').format();
                    const checkinEstimated=args.start.value;
                   const nuevaReserva= {
                      "guests": 2,
                      "children": 0,
                      checkoutEstimated,
                      checkinEstimated,
                      servicesList:[],
                      customersList:[],
                      vehiclesList:[],
                      nuevaReserva:true,
                      nights:moment(checkoutEstimated).diff(checkinEstimated,'days'),
                      "state": "",
                      "way": "Presencial",
                      "roomsList":[listaDeptos.find(x=>x.number==args.resource)],
                  }
                    dispatch(setReserva(nuevaReserva))
                    dispatch(setModal(true));
                    this.scheduler.clearSelection()
                   }
                  else{
                    alert("Solo se puede crear una reserva en la seccion de confirmadas")
                    window.location.reload(false);
                  }
                  }}
                  ref={component => { this.scheduler = component && component.control; }}
                />
      </React.Fragment>
        );
    }
}

export default connect(state=>({checkoutTime:state.mainReducer.checkoutTime,listaDeptos:state.mainReducer.listaDeptos,refrescarDispoYPrecios:state.mainReducer.refrescarDispoYPrecios,isMobile:state.mainReducer.isMobile}),null)(Scheduler)



