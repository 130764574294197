import React,{useState,useEffect, useMemo} from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {CapitalFirst,TransformToLocalCurrency} from '../../../../Funciones/utils'
import Graficos from './graficos'
import {Table,Label} from 'semantic-ui-react'

const ListaPagos=({isCaja,tableHeaderClass, nombre,listaPagos,isMobile,mostrarGrafico})=>{
    const [labels,setLabels]=useState()
    const [datasets,setDatasets]=useState()
    const [usersInfo,setUsersInfo]=useState()
    const tipoPagos=useSelector(x=>x.mainReducer.tipoPagos)
    const cashMovements=useSelector(x=>x.mainReducer.cashMovements)
    useEffect(()=>{
      tipoPagos.map(x=>CapitalFirst(x.tipo))
      const labels=listaPagos.reduce((acom,current)=>acom.find(x=>x==current.specificUsername)?acom:[...acom,current.specificUsername],[]).sort()
      const datasets=tipoPagos.map(x=>({label:CapitalFirst(x.tipo),backgroundColor:x.backgroundColor,data:labels.map(label=>listaPagos.filter(y=>!y.isRefund).filter(y=>y.method==x.tipo&&y.specificUsername==label).reduce((acom,current)=>acom+current.amount,0))}))
      setLabels(labels)
      setDatasets(datasets)
      setUsersInfo(labels.map((x,i)=>{
        const auxObj={}
        auxObj.usuario=x;
        auxObj.pagos=datasets.map(y=>{
        const obj={}
        obj.nombre=y.label;
        obj.valor=y.data[i];
        obj.sumar=true;
        return obj;
      })
      auxObj.pagos=[...auxObj.pagos,{nombre:'Total',valor:auxObj.pagos.reduce((prev,current)=>current.valor+prev,0),sumar:false,important:true}]
    return auxObj;
    }))
    },[listaPagos])
    const retiros=useMemo(()=>tipoPagos.map((x,i)=>({tipo:x.tipo,valor:cashMovements.filter((y,k)=>y.isWithdrawal&&!y.isMoneyLeft&&(x.tipo==y.type||(i==0&&y.type==null))).reduce((prev,cur)=>prev+cur.amount,0)})),[cashMovements])
    const proveedores=useMemo(()=>[{tipo:'Efectivo',valor:cashMovements.filter((y,k)=>y.isWithdrawal&&!y.isMoneyLeft&&('Proveedores'==y.type)).reduce((prev,cur)=>prev+cur.amount,0)},...tipoPagos.filter(x=>x.tipo!='Efectivo').map((x)=>({tipo:x,valor:0}))],[cashMovements])
    const ingresos=useMemo(()=>tipoPagos.map((x,i)=>({tipo:x.tipo,valor:cashMovements.filter((y,k)=>!y.isWithdrawal&&!y.isMoneyLeft&&(x.tipo==y.type||(i==0&&y.type==null))).reduce((prev,cur)=>prev+cur.amount,0)})),[cashMovements])
    const saldoFinal=useMemo(()=>tipoPagos.map((x,i)=>({tipo:x.tipo,valor:cashMovements.filter((y,k)=>y.isWithdrawal&&y.isMoneyLeft&&(x.tipo==y.type||(i==0&&y.type==null))).reduce((prev,cur)=>prev+cur.amount,0)})),[cashMovements])   
    const totales=datasets?.map(y=>({
        tipo:y.label,
        valor: usersInfo.reduce((prev,current)=>prev+current.pagos.filter(x=>x.nombre==y.label).reduce((x,y)=>x+(y.sumar?parseInt(y.valor):0),0),0)
    }))
  

    const registroCaja=[
    {name:'Inicio de caja',movements:ingresos??[]},
    {name:'Retiros',isRetiro:true,movements:retiros??[]},
    {name:'Pago Proveedores',isRetiro:true,movements:proveedores??[]},
    {name:'Total Neto',isSaldo:true,isImportant:true,movements:
    ingresos.map(x=>({tipo:x.tipo,valor:x.valor-((x.tipo=="Efectivo"&&proveedores.length>0)?proveedores[0].valor:0)-retiros.find(y=>y.tipo==x.tipo).valor+totales?.find(y=>CapitalFirst(y.tipo)==CapitalFirst(x.tipo))?.valor??0}))
  },{name:'En caja al cierre',movements:saldoFinal??[]}]
  
    if (nombre=="Total") {
      window.totales=registroCaja.find(x=>x.name=="Total Neto").movements
    }
  
    return(
    <React.Fragment>
           {usersInfo&&usersInfo.length>0&&      
    <Table celled definition={!nombre} >
    <Table.Header>
      <Table.Row>
        {nombre&&<Table.HeaderCell className={tableHeaderClass} textAlign={'center'} colSpan='10' >{nombre}</Table.HeaderCell>}
      </Table.Row>
    </Table.Header>
      <Table.Header>
        <Table.Row>
        {<Table.HeaderCell style={{borderTop:0}}>              
        </Table.HeaderCell>}
          {usersInfo[0].pagos.map((y,i)=><>
                <Table.HeaderCell key={i}>{CapitalFirst(y.nombre)}</Table.HeaderCell>
                </>
            )}
        </Table.Row>
      </Table.Header>
      <Table.Body >
          {usersInfo.map((x,i)=>( 
          <>
          <Table.Row>
            <Table.Cell>{CapitalFirst(x.usuario)}</Table.Cell>
            {x.pagos.map(y=>(
              <Table.Cell className={y.important?'ImportantCell':''}>{y.valor==0?'-':`${TransformToLocalCurrency(y.valor)}`}</Table.Cell>
            ))}
              </Table.Row>
          </>
          ))}
        <Table.Row>
            <Table.Cell>          
              <Label size={'big'} ribbon>Total ventas</Label>
            </Table.Cell>
                {
                totales.map(x=><>
                    <Table.Cell style={{fontSize:'20px'}}>{TransformToLocalCurrency(x.valor,2)
                  }</Table.Cell> 

                    </>
                )}
            <Table.Cell className='ImportantCell' textAlign={false?'center':'left'} style={{fontSize:'24px'}}>{usersInfo.reduce((prev,current)=>prev+current.pagos.reduce((x,y)=>x+(y.sumar?parseInt(y.valor):0),0),0).toLocaleString('es-ar', {
            style: 'currency',
            currency: 'ars',
          })
        }</Table.Cell>
        </Table.Row>
          {isCaja&&
          registroCaja.map((x,i)=>( 
          <>
          <Table.Row>
            <Table.Cell>
              {x.isSaldo&&<Label size={'big'} ribbon>{x.name}</Label>}
              {!x.isSaldo&&CapitalFirst(x.name)}
              </Table.Cell>
            {x.movements?.map(y=>(
              <Table.Cell style={{fontSize:x.isSaldo?'20px':''}}>{(y.valor==0&&!x.isSaldo)?'-':`${x.isRetiro?'-':''} ${TransformToLocalCurrency(y.valor)}`}</Table.Cell>
            ))}
             <Table.Cell className={'ImportantCell'} style={{fontSize:x.isImportant?'24px':''}}>{`${x.isRetiro?'-':''} ${TransformToLocalCurrency(x.movements?.reduce((prev,curr)=>prev+curr.valor,0))}`}</Table.Cell>
            </Table.Row>
          </>
          ))}


          <Table.Row>
          </Table.Row>
        </Table.Body>
      </Table>}
      {!isMobile&&mostrarGrafico&&<Graficos tipoPagos={tipoPagos} listaPagos={listaPagos} labels={labels} datasets={datasets}/>}
    </React.Fragment>
    )
  }

export default ListaPagos

