import React,{useEffect, useMemo,useRef} from "react"
import { Button,Modal, ModalHeader,Input, ModalBody, ModalFooter,} from 'reactstrap';
import {Table,Label,Checkbox,Icon,Input as InputSemantic} from 'semantic-ui-react'
import {useSelector,useDispatch} from 'react-redux'
import moment from 'moment'
import {isConsumidorFinal,connectPrinter,consumdorFinalObject,chooseCustomer} from '../../../Funciones/utils'	


export const ConfirmarPago = ({makeBill,setMakeBill,newReservation,listaDeptos,isMobile,pagarLuego,onPay,open,setOpen,loading,fechaSalida,noches,descripcion,forma,precio,nombreCuenta,customersList,setForma}) => {
  const {tipoPagos} = useSelector(x => x.mainReducer)
  const togglePrinter = useSelector(x => x.printerReducer.togglePrinter)
  const billSession = useSelector(x => x.billReducer)
  const {printer} = useSelector(x => x.printerReducer)
  const billTypes= useSelector(x=>x.billReducer?.billTypes)
  const cuitInput=useRef()
  const loadingCuitSearch=billSession.loadingCuitSearch;
  const  dispatch = useDispatch();
  useEffect(()=>{
    if (open) {
      setForma("")
    }
  },[open])
  useEffect(()=>{
    if (isConsumidorFinal(precio,forma)) {
      dispatch({type:'SET_CUSTOMER',payload:consumdorFinalObject()});
    }
    else if (customersList.length>0){
      dispatch({type:'SET_CUSTOMER',payload:chooseCustomer(customersList)});
    }
  },[forma,billSession.billInfo.typeBill])
 let formas=["",...tipoPagos.map(x=>x.tipo)]
 if(pagarLuego){
   formas=[...formas,'Pagar Luego']
 }

 const canBill=useMemo(()=>{
   return (isConsumidorFinal(precio,forma)||customersList.length!=0)},[precio,forma,customersList])
   
 const customerBillingList=isConsumidorFinal(precio,forma)?[consumdorFinalObject(),...customersList]:customersList
 return (
    <React.Fragment>

      <Modal style={{maxWidth:"600px"}} isOpen={open} toggle={()=>{setOpen(false)}} >
          <ModalHeader style={{borderBottom:"none",fontSize:"20px !important"}} toggle={()=>{setOpen(false)}}
          >Confirmar Pago
          </ModalHeader>
          <ModalBody>
          <Table definition>
        <Table.Body >
          <Table.Row>
            <Table.Cell>Cuenta de</Table.Cell>
            <Table.Cell>{nombreCuenta}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Componentes</Table.Cell>
            <Table.Cell>{descripcion}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={2}>Noches</Table.Cell>
            <Table.Cell>{noches}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Forma pago</Table.Cell>
            <Table.Cell>
              {formas.find(x=>x==forma)!=undefined?
                            formas.length>0&&<Input id={"selectCondicion"} name="forma" value={forma} onChange={(e)=>{setForma(e.target.value)}}
                            type="select">{formas.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
              :"pagar luego"}   

   
            </Table.Cell>
            
          </Table.Row>
          <Table.Row>
            <Table.Cell width={2}>Fecha salida</Table.Cell>
            <Table.Cell style={{fontSize:'20px'}}>{moment(fechaSalida).format('dddd DD MMMM')}</Table.Cell>
          </Table.Row>
          {billSession.toggleBilling&&formas.find(x=>x==forma)!=undefined&&<Table.Row>
            <Table.Cell width={2}>Extra</Table.Cell>
            <Table.Cell style={{fontSize:'20px'}}>
              <Checkbox 
              disabled={!isConsumidorFinal(precio,forma)&&customersList.length==0}
              label={"Facturar"}
              slider 
              onChange={(e)=>{setMakeBill(!makeBill)}} 
              checked={makeBill&&canBill}/>
              <br></br>
              {!canBill&&<Label  style={{marginTop:"10px"}} color="red" >No hay ningun cliente con dni para facturar</Label>}
              {canBill&&makeBill&&<><Input 
              style={{marginTop:"10px"}}
              autocomplete="off"
                            type="select"
                            value={billSession.billInfo.typeBill}
                            onChange={(e) => dispatch({type:"SET_TYPE_BILL",payload:e.target.value})}
                            name="typeBill" id={"typeBill"}>
                            {
                            
                              billTypes.map((x,i)=>(<option key={i} value={x}>{x}</option>))
                            
                            }
              </Input>
              {billSession.billInfo.typeBill=="Factura A"&&<InputSemantic
                            ref={cuitInput}
                              action={{ 
                                icon: loadingCuitSearch?"loading":'search',
                                onClick: () => dispatch({type:"CUIT_INFO",payload:cuitInput.current.inputRef.current.value})
                              
                            }} 
                            disabled={loadingCuitSearch}
                            placeholder='CUIT'
                            style={{fontSize:"14px !important",marginTop:"10px"}}
                            size={"mini"}
                            fluid={true}
                            loading={loadingCuitSearch}
                            onResultSelect={false}

                            />}
              {billSession.billInfo.typeBill!="Factura A"&&<Input 
              style={{marginTop:"10px"}}
              autocomplete="off"
                            type="select"
                            value={billSession.customer?.fullName}
                            onChange={(e) =>     {dispatch({type:'SET_CUSTOMER',payload:e.target.value=='Consumidor Final'?consumdorFinalObject():customersList.find(x=>x.fullName==e.target.value)})}}
                            name="dni" id={"dni"}>
                            {
                            
                            customerBillingList.map(x=>(<option key={x.fullName} value={x.fullName}>{`${x.fullName} ${x.age?`(${x.age})`:""}`}</option>))
                            
                            }
                </Input>}
              {!loadingCuitSearch&&billSession.customer?.searchedCuit&&<Input 
              style={{marginTop:"10px"}}
              autocomplete="off"
                            type="select"
                            value={billSession.customer?.fullName}
                            name="dni" id={"dni"}>
                            {
                            
                            [billSession.customer].map(x=>(<option key={x.fullName} value={x.fullName}>{`${x.fullName} ${x.age?`(${x.age})`:""}`}</option>))
                            
                            }
              </Input>}
              </>}
              {/* <Checkbox slider label='Imprimir comprobante' /> */}
            </Table.Cell>
          </Table.Row>}
          {togglePrinter&&formas.find(x=>x==forma)!=undefined&&<Table.Row>
            <Table.Cell width={2}>Imprimir</Table.Cell>
            <Table.Cell style={{fontSize:'20px'}}>
              {!printer.isConnected&&
              <>
              <Label color="red" >Comandera no encontrada</Label>
              {/* reload button */}
              <Button color="teal" onClick={()=>connectPrinter()}>
                <Icon name="refresh" size="big"/>
              </Button>

              </>
              }
              {printer.isConnected&&<>
                <Checkbox 
                label={"Imprimir ticket"}
                slider 
                // disabled={!printer.isConnected}
                onChange={(e)=>{
                  dispatch({type:"SET_PRINT_TICKET",payload:!printer.printTicket})
                }} 
                checked={printer.printTicket}/>
                {newReservation&&<br></br>}
                {newReservation&&<Checkbox 
                label={"Imprimir checkin"}
                slider 
                disabled={!printer.isConnected}
                onChange={(e)=>{
                  dispatch({type:"SET_PRINT_CHECKIN",payload:!printer.printCheckin})
                }} 
                checked={printer.printCheckin}/>}
                {newReservation&&<br></br>}
                {newReservation&&<Checkbox 
                label={"Imprimir checkout"}
                slider 
                disabled={!printer.isConnected}
                onChange={(e)=>{
                  dispatch({type:"SET_PRINT_CHECKOUT",payload:!printer.printCheckout})
                }} 
                checked={printer.printCheckout}/>}
              </>}
              {/* <Checkbox toggle label='Imprimir comprobante' /> */}
            </Table.Cell>
          </Table.Row>}
          <Table.Row>
            <Table.Cell>          
              <Label size={'big'} ribbon>Total</Label>
            </Table.Cell>
            <Table.Cell textAlign={isMobile?'center':'left'} style={{fontSize:'24px'}}>${precio}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>   
          </ModalBody>
          <ModalFooter>
            <Button disabled={loading} id={"buttonTest"} style={{float:"right"}} color="grey" size={"lg"} onClick={()=>{setOpen(false)}}>Cancelar</Button>
            <Button disabled={loading} id={"buttonTest"} style={{float:"right"}} color="info" size={"lg"} 
              onClick={()=>{
                onPay(forma,listaDeptos,makeBill)
              }
          }>Confirmar</Button>
          </ModalFooter>
        </Modal>
        
      </React.Fragment>
   
    )
}



export default ConfirmarPago

