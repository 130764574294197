import { Normalizar, ParseScan,DevolverComponentesParaJSX } from '../../../../Funciones/utils'
const basicCustomer={fullName:'',age:'',birthdate:"",email:null,phone:'',geo:JSON.stringify({})}
const SortList=(list)=>{
    return list.sort(function(a, b){
        if(a.fullName < b.fullName) { return -1; }
        if(a.fullName > b.fullName) { return 1; }
        return 0;
    })
}

export const initialState = {customersList: [],scanerReady:true,customer:{uniqueId:Math.random()}};

export function reducer(state, action) {
    switch (action.type) {
      //CUSTOMER
      case 'UPDATE_CUSTOMER':
        const customersList=[...state.customersList.filter(x=>x.uniqueId!=action.payload.uniqueId)];
        let customer=action.payload;
        return {...state,customer:{...basicCustomer,...customer},customersList:SortList([...customersList,{...action.payload}].sort((a,b)=>a.fullName))};
      case 'SELECT_CUSTOMER':
        return {...state,customer:state.customersList.find(x=>x.uniqueId==action.payload)};
      case 'REMOVE_CUSTOMER':
        return {...state,customersList:SortList(state.customersList.filter(x=>x.uniqueId!=action.payload))};
      case 'INIT_CUSTOMERS_LIST':
        return {...state,customersList:SortList(action.payload.map(x=>({...x,uniqueId:Math.random()})))};
      case 'ADD_CUSTOMER':
        const uniqueId=Math.random();
        return {...state,customer:{
            uniqueId,
           ...basicCustomer
        },
        customersList:[...state.customersList]
    };
    //SCANER
    case 'SET_SCANER':
      return {...state,scanerReady:action.payload};
      default:
        throw new Error();
    }
  }