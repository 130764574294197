import React,{useState} from 'react';
import {Icon,Divider} from 'semantic-ui-react'
import { useSelector} from 'react-redux'
import {CapitalFirst} from '../../../../Funciones/utils'
import {Col,Row} from 'reactstrap';
import TablaUsuarios from './tablaUsuarios'
import { Collapse } from 'react-collapse';


const TablaTotales=({paymentList,isCaja})=>{
    const isMobile=useSelector(x=>x.mainReducer.isMobile)
    const preciosXFecha=useSelector(x=>x.mainReducer.preciosXFecha)
    const [abrirComponentes,setAbrirComponentes]=useState(false)

    return(
        <>

            <TablaUsuarios isCaja={isCaja} movimientosCaja={true} nombre={"Total"} tableHeaderClass={'IsPrimary'} mostrarGrafico={false} listaPagos={paymentList.filter(x=>!x.canceled)} isMobile={isMobile}/>  
            {preciosXFecha.length>1&&
            <>
            {abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle up' size='small' /></Divider>}
            {!abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle down' size='small' /></Divider>}        
           
                    <Collapse isOpened={abrirComponentes}>
                        <Row>
                            {preciosXFecha.map(x=> 
                                    <Col  xs={12}>
                                        <TablaUsuarios  nombre={CapitalFirst(x.type)} tableHeaderClass={'IsSecondary'} mostrarGrafico={false} listaPagos={paymentList.filter(y=>y.reservationType==x.type)} isMobile={isMobile}/>
                                    </Col>
                                    )}       
                        </Row>     
                    </Collapse></>} 
                <br></br>
            </>
    )
}

export default TablaTotales;
