import React,{useState,useEffect} from "react"
import Caja from './caja'
import moment from 'moment'

export const CalcularPrecios=(diaPlaya)=>{
    const [fechaInicio,setFechaInicio]=useState()
    const [fechaFin,setFechaFin]=useState()
    const reserva= {
        guests: 2,
        children: 0,
        checkoutEstimated:moment(fechaFin).format("YYYY-MM-DD"),
        checkinEstimated:moment(fechaInicio).format("YYYY-MM-DD"),
        servicesList:[],
        customersList:[],
        vehiclesList:[],
        nuevaReserva:true,
        calcularPrecio:true,
        nights:0,
        state: "",
        way: "Presencial",
        roomsList:[],
}

    useEffect(()=>{
        setFechaInicio(moment().format("YYYY-MM-DD"))
        setFechaFin(moment().add(1,'days').format('YYYY-MM-DD'))
    },[])
    return(<>
      <Caja calcularPrecio={true} reserva={reserva}/>
    </>
)}
 