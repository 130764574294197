import React,{useState,useEffect,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
import {Button,Menu,Popup,Header,Divider, Input as InputSemantic,Label, Icon} from 'semantic-ui-react'
import './precioDormis.css'
import { Fragment } from 'react';
import { HabitacionesDisponiblesXFecha } from '../../../Funciones/utils';

export const ActualizarDisponibilidada=async(fecha,roomId,setListaDisponibilidad)=>{
  let primerDia=moment(fecha);
  let ultimoDia=moment(fecha).add(30,"days");
  let lista=[]
  const disponibilidad={}//await AgarrarDisponibilidad(primerDia,ultimoDia)
  const dias= ultimoDia.diff(primerDia,'days')
  for (let index = 0; index < dias; index++) {
    const fechaFormateada=primerDia.format('YYYY-MM-DD');
    const obj={fecha:fechaFormateada,disponibilidad:disponibilidad[roomId][fechaFormateada]}
    lista=[...lista,obj]
    primerDia.add(1,'days')
  }
  setListaDisponibilidad(lista)
 }

const ChangePricesPopup=({input,fechaEntrada,fechaSalida,nombreChannel,nombrePrecio,precioPopup,setPrecioPopup,setOpenPopup,tipoPrecio,isDispo,isRepublicar,setStartDrag,startDrag})=>{
  const dispatch=useDispatch();
  const mandarPrecios=()=>{
    if (isRepublicar||isDispo||precioPopup>0) {
      dispatch({type:isRepublicar?'MODIFY_AUTOUPDATE':isDispo?`MODIFY_AVAILABILITY`:'MODIFY_PRICES',payload:{tipoPrecio,from:fechaEntrada,to:fechaSalida,item:nombrePrecio,price:precioPopup,availability:precioPopup,autoUpdateAvailability:precioPopup}})
    }
    else{
      alert('Falta completar el campo')
    }
  }
  return(
  <Fragment>
    <div className="d-flex flex-column bd-highlight">
      {/* align one item in left and tother in right */}
    <div className="d-flex justify-content-between  ">
        <div className="p-2 bd-highlight" style={{cursor:"pointer"}} onClick={()=>{setOpenPopup(false)}}>
          <Header  size='large'>{nombreChannel}</Header>
        </div>
        <div className="p-2 bd-highlight" style={{cursor:"pointer"}} onClick={()=>{setOpenPopup(false)}}>
            <Icon name="close" size="large" color="black"/>
        </div>
    </div>
      <br/>
      <div className="d-flex flex-column pl-2 pr-2 bd-highlight">
        {/* Select date */}
        <InputSemantic label="Desde"  style={{marginBottom:"5px"}} type='date' value={fechaEntrada} onChange={(e,{value})=>setStartDrag({...startDrag,fechaInicio:value})}/>
        <InputSemantic label="Hasta"  type='date' value={fechaSalida} onChange={(e,{value})=>setStartDrag({...startDrag,fechaFin:value})}/>
      </div>
      <Divider />
      <div className="py-2 bd-highlight">
        <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
          <div className={'p-2 display-5'} style={{width:'150px'}}>{isRepublicar?'Republicar automatico':isDispo?'Disponibilidad':'Precio'} <strong>{nombrePrecio}</strong></div>
          <div className={'p-2'} >  <InputSemantic labelPosition='left' type='number' placeholder=''>
            {!isDispo&&!isRepublicar&&<Label basic>$</Label>}
            <input
            onKeyPress={(target)=>{
              if (target.charCode == 13) {
                mandarPrecios();
              }
            }}
            ref={input} style={{width:'100px'}} value={precioPopup} onChange={(e)=>setPrecioPopup(e.target.value)}/>
          </InputSemantic>
          </div>
          </div>
        </div>
        <div className="pl-2 pt-2 bd-highlight">    
        <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
        <div className={'px-2 col-md-6'} >
          <Button color="teal" fluid onClick={()=>{
            mandarPrecios();
          }}>Guardar</Button>
        </div>
        <div className={'px-2 col-md-6'} >
        <Button fluid onClick={()=>{setOpenPopup(false);setStartDrag({})}}>Cerrar</Button>
        </div>
        </div>
      </div>
    </div>
  </Fragment>
)}

const Input = ({precio,fecha,usd,nombrePrecio,startDrag,setStartDrag,setOpenPopup}) => {
      return (
          <React.Fragment>
              <div 
                  onMouseOver={()=>{
                    //Si la fecha es mayor a la fecha que hay en setdrag remplazarla
                    if (startDrag.start&&startDrag.fechaFin!=fecha) {
                      if (moment(startDrag.fechaInicio).diff(moment(fecha),'days')>=0) {
                        setStartDrag({...startDrag,fechaInicio:fecha})
                      }
                      else
                      {
                        setStartDrag({...startDrag,fechaFin:fecha})

                      }

                  }}}
                  onMouseUp={(e)=>{setOpenPopup(true);if (startDrag.fechaInicio) {
                    setStartDrag({...startDrag,start:false})
                  }}}
                  onMouseDown={(e)=>{setStartDrag({nombrePrecio,fechaInicio:fecha,fechaFin:fecha,start:true})}} 
                  className={`Noselect rna-grid__cell rna-value-wrapper js-rna-cell js-drag-and-select rna-grid__cell--actionable ${(startDrag.nombrePrecio==nombrePrecio&&moment(fecha).isBetween(startDrag.fechaInicio,startDrag.fechaFin,'day','[]'))?`Select`:''}`} 
                  data-date="2019-11-06" style={{backgroundColor:(moment(fecha).isoWeekday() === 6 || moment(fecha).isoWeekday() === 7)?"rgb(255, 248, 183)":"#fff"}}>                    
                  {/*<input className="rna-input rna-input--hidden" type="number" value={precioAux} onChange={(e)=>{setPrecioAux(e.target.value)}} onKeyPress={(event)=> {if(event.key === 'Enter'){}}}  step="any"  style={{ bottom: 0, top: "auto", textAlign: "right", zIndex: 4}}/>*/}
                  
                  <span className={`rna-value rna-value--bottom rna-value--caption `} >
                      <div className="rna-value--currency">
                          {usd?`US$`:`AR$`}
                      </div>
                      {precio}
                  </span>
              </div>
          </React.Fragment>
      );
  };
  
const MesYAno = (props) => {
      return (
          <React.Fragment>
               <div className="rna-title--headline" style={{borderRight:"0px",padding:"0.5rem"}}>{props.texto}</div>
          </React.Fragment>
      );
  };
  
const Dia = ({texto,style,isWeekend,startDrag,setStartDrag,fecha,setOpenPopup,isDispo,isRepublicar}) => {
      return (
          <React.Fragment>
              <div  
                 onMouseOver={()=>{
                  //Si la fecha es mayor a la fecha que hay en setdrag remplazarla
                  if ((isDispo||isRepublicar)&&startDrag?.start&&startDrag?.fechaFin!=fecha) {
                    setStartDrag({...startDrag,fechaFin:fecha})}
                }}
                onMouseUp={(e)=>{if(isDispo||isRepublicar){setOpenPopup(true)};if (startDrag?.fechaInicio) {setStartDrag({...startDrag,fechaFin:fecha,start:false})}}}
                onMouseDown={(e)=>{if(isDispo||isRepublicar){setStartDrag({isDispo,isRepublicar,fechaInicio:fecha,start:true})}}}
                className={`Noselect rna-grid__cell rna-value-wrapper js-drag-and-select js-rna-cell rna-grid__cell--actionable ${(((startDrag?.isRepublicar&&isRepublicar)||(startDrag?.isDispo&&isDispo))&&moment(fecha).isBetween(startDrag.fechaInicio,startDrag.fechaFin,'day','[]'))?`Select`:''}`} data-date="2019-10-07"  style={{backgroundColor:isWeekend?"rgb(255, 248, 183)":"#fff"}}>
                              {/* <input className="rna-input js-rna-cell-input rna-input--hidden" id="rna-rts-input-55835201-2019-10-07" defaultValue={1} data-at-type="rts" data-at-room-id={55835201} data-at-date="2019-10-07" data-on-click="selectInput(event)" data-preserve-el-when-focused type="number" min={0} step={1} data-on-keyup="handleSubmitOrCancelRTS('55835201', null, '2019-10-07', event)" data-on-blur="updateRoomsToSell('55835201', null, '2019-10-07', event)" /> */}
                              <span style={style} className="rna-value rna-value--bottom">
                                  {texto}
                              </span>
              </div>
          </React.Fragment>
      );
  };


const Disponibilidad = ({disponibilidad,fecha,isWeekend,tipoPrecio}) => {
  const dispatch=useDispatch();
      return (
          <React.Fragment>
            <div className="rna-grid__cell js-rna-cell rna-value-wrapper "  data-date="2019-10-07" style={{backgroundColor:(isWeekend)?"rgb(255, 248, 183)":"#fff"}}>
             <span id="rna-55835201-2019-10-07-label"  onClick={()=>{dispatch({type:'MODIFY_AVAILABILITY',payload:{tipoPrecio,from:fecha,to:fecha,availability:disponibilidad==0?1:0}})}} style={!disponibilidad?{backgroundColor:"#e8bebe"}:{}} className={`rna-label rna-label--primary  rna-label--positive rna-label--actionable ${disponibilidad}`} data-on-click="toggleRoomStatus('55835201', '2019-10-07', event)">
             &nbsp;
             </span>
               </div>
          </React.Fragment>
      );
  };
const Buscador= ({setFechaInicio,setFechaFin})=>{
    var listaMeses=[]
    for (let index = 0; index < 12; index++) {
      listaMeses=[...listaMeses,moment().startOf('month').add(index,"month")];
    }
  
    const actualizarFechas=(setFechaInicio,setFechaFin,fechaElegida)=>{
      if (moment(fechaElegida).format('MM')==moment().format('MM')) {
        setFechaInicio(moment().format('YYYY-MM-DD'))
        setFechaFin(moment().add(31,"days").format('YYYY-MM-DD'))
      }
      else{
        setFechaInicio(moment(fechaElegida).startOf('month').format('YYYY-MM-DD'));
        setFechaFin(moment(fechaElegida).endOf('month').format('YYYY-MM-DD'));
      }
      
    }
    return (
    <div className="row">
      <div className="col-md-3">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Mes</h4>
        <select className="form-control" id="exampleFormControlSelect1" onChange={(event)=>{actualizarFechas(setFechaInicio,setFechaFin,event.target.value)}}>
          {listaMeses.map((x,i)=><option value={x.format("YYYY-MM-DD")} key={i}>{x.format("MMMM YYYY")}</option>)}
        </select>
      </div>
  </div>
    )
  }

const Main=({roomType})=>{
  const preciosXFecha=useSelector(state=>state.mainReducer.preciosXFecha);
  const preciosXFechaOta=useSelector(state=>state.mainReducer.preciosXFechaOta);
  const listaReservas=useSelector(state=>state.mainReducer.listaReservas);
  const tipoPrecio=useSelector(state=>state.mainReducer.tipoPrecio);
  const loading=useSelector(state=>state.mainReducer.priceLoading);

 
  return(
  <LoadingOverlay
  active={loading}
  spinner
  text='Actualizando'
  >   
    <Calendario listaReservas={listaReservas} usd={false} precioElegido={preciosXFecha.find(x=>x.type==tipoPrecio)} nombreChannel={`Mostrador`}/>
    {preciosXFechaOta.filter(x=>x.type.split('-')[0]==tipoPrecio).map(x=>
      <Fragment>
        <br></br>
        <Calendario usd={true} precioElegido={x} nombreChannel={x.name} listaReservas={listaReservas}/>

     </Fragment>
          )}
  </LoadingOverlay>)

}
//----------------------------------------------------------------------------------------------
const Calendario = ({precioElegido,usd,nombreChannel,listaReservas}) => {
      const [listaDisponibilidad,setListaDisponibilidad] = useState([]);
      const [listaDisponibilidadReal,setListaDisponibilidadReal] = useState([]);
      const [listaAutoAjustar,setListaAutoAjustar] = useState([]);
      const [fechaInicio,setFechaInicio] = useState("");
      const [fechaFin,setFechaFin] = useState("");
      const [isOnline,setIsOnline] = useState(false);
      const [listaDeListaDePrecios,setListaDeListaDePrecios] = useState([]);
      const [precioPopup,setPrecioPopup]=useState(0)
      const [openPopup,setOpenPopup]=useState(false)
      const [startDrag,setStartDrag]=useState({});
      const input=useRef()
      useEffect(()=>{
        setFechaInicio(moment().format('YYYY-MM-DD'))
        setFechaFin(moment().add(31,"days").format('YYYY-MM-DD'))
      },[])
      useEffect(()=>{
        if (input.current) {
          input.current.focus()
          setPrecioPopup('')
        }
      },[openPopup])
      useEffect(()=>{
        setListaDisponibilidadReal(HabitacionesDisponiblesXFecha(fechaInicio,fechaFin))
      },[fechaInicio,fechaFin,listaReservas])
      useEffect(()=>{
        if (precioElegido) {
          const precioTipo=precioElegido;
          setListaDisponibilidad(precioTipo.disponibilidad.filter(x=>moment(x.fecha).isBetween(fechaInicio,fechaFin,null,'[]')))
          setListaAutoAjustar(precioTipo.autoAjustar?.filter(x=>moment(x.fecha).isBetween(fechaInicio,fechaFin,null,'[]'))??[])
          const p=precioTipo.precios.map(x=>({
            ...x,
            list:x.list.filter(y=>moment(y.fecha).isBetween(fechaInicio,fechaFin,null,'[]'))
            }))
          setListaDeListaDePrecios(p)
          setIsOnline(precioTipo.isOnline)
        }
      
      },[precioElegido,fechaInicio,fechaFin])

      if (!precioElegido){
        return <></>
      }
      return (
             <>
                {/*<MenuTipoHabitacion informacionHotel={informacionHotel} isMobile={isMobile} mostrarTipoHabitaciones={mostrarTipoHabitaciones} setMostrarTipoHabitaciones={setMostrarTipoHabitaciones} dispatch={dispatch}/>*/}
                <div className="bordeado">
                  <Buscador setFechaInicio={setFechaInicio} setFechaFin={setFechaFin}/>
                  {/*isOnline&&<RangoFechasDisponibilidad tipoPrecio={precioElegido.type} setListaDeListaDePrecios={setListaDeListaDePrecios}/>*/}

                  {/*<RangoFechas tipoPrecio={precioElegido.type} priceList={listaDeListaDePrecios} />*/}
                
              <div className="rna-grid js-scroll-sync " id="rna-grid" data-render-id="rna-matrix">

                <Header size={'large'}>{nombreChannel}</Header>
                  <div className="rna-grid__row " id="net-booked-55835201" data-render-id="rna-room-block" style={{ border: "0px" }}>
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left rna-title" style={{ border: "0px" }}>
                    </div>
                    {                      
}
                    {
                      (listaDeListaDePrecios[0]) ? listaDeListaDePrecios[0].list.map((x, i) => <MesYAno texto={i == 0 ? moment(x.fecha).format("MMMM").toUpperCase() : i == 1 ? moment(x.fecha).format("YYYY") : ""} key={i} />) : ""
                    }

                  </div>
                  <div className="rna-grid__row js-rna-bulk-form-row js-onboarding-rooms" data-render-id="rna-room-block" data-form-type="rooms_to_sell" id="rooms-to-sell-55835201">
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                      <div className="rna-title">
                        Dia
                              </div>
                    </div>
                    {
                      (listaDeListaDePrecios[0]) ? listaDeListaDePrecios[0].list.map((x, i) => <Dia  isWeekend={moment(x.fecha).isoWeekday() === 6 || moment(x.fecha).isoWeekday() === 7} texto={moment(x.fecha).format("dd D")} key={i} />) : ""
                    }
                  </div>

                  {isOnline&&<div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201">
                    
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left rna-title">
                      Estado
                    </div>
                    {
                       (listaDisponibilidad.length>0) ? listaDisponibilidad.map((x,i)=><Disponibilidad  tipoPrecio={precioElegido.type} isWeekend={moment(x.fecha).isoWeekday() === 6 || moment(x.fecha).isoWeekday() === 7} disponibilidad={x.disponibilidad} fecha={x.fecha}  key={i}/>):""
                    }
                    </div>}

                  {/*<div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201">
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                        <div className="rna-title">
                        Lugares en venta
                                </div>
                      </div>
                      {
                        (listaDisponibilidad.length>0) ? listaDisponibilidad.map((x, i) => <Dia style={{textAlign:"center",fontSize:16,fontWeight:700}} fecha={x.fecha} texto={x.disponibilidad} key={i} />) : ""
                      }
                    </div>*/}
                  {<div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201" >
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                        <div className="rna-title">
                       Disponibilidad real
                                </div>
                      </div>
                      {
                        (listaDisponibilidadReal.length>0) ? listaDisponibilidadReal.map((x, i) => <Dia fecha={x.fecha} startDrag={startDrag} setStartDrag={setStartDrag} setOpenPopup={setOpenPopup} isDispo={false} isRepublicar={false} style={{textAlign:"center",fontSize:16,fontWeight:700}} isWeekend={moment(x.fecha).isoWeekday() === 6 || moment(x.fecha).isoWeekday() === 7} texto={x.disponibilidad} key={i} />) : ""
                      }
                  </div>}
                  {isOnline&&<div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201" >
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                        <div className="rna-title">
                       Lugares disponibles
                                </div>
                      </div>
                      {
                        (listaDisponibilidad.length>0) ? listaDisponibilidad.map((x, i) => <Dia fecha={x.fecha} startDrag={startDrag} setStartDrag={setStartDrag} setOpenPopup={setOpenPopup} isDispo={true} isRepublicar={false} style={{textAlign:"center",fontSize:16,fontWeight:700}} isWeekend={moment(x.fecha).isoWeekday() === 6 || moment(x.fecha).isoWeekday() === 7} texto={x.disponibilidad} key={i} />) : ""
                      }
                  </div>}
                  {isOnline&&listaAutoAjustar.length>0&&<div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201" >
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                        <div className="rna-title">
                          Republicar automatico
                        </div>
                      </div>
                      {
                        (listaAutoAjustar.length>0) ? listaAutoAjustar.map((x, i) => <Dia fecha={x.fecha} startDrag={startDrag} setStartDrag={setStartDrag} setOpenPopup={setOpenPopup} isDispo={false} isRepublicar={true} style={{textAlign:"center",fontSize:16,fontWeight:700}} isWeekend={moment(x.fecha).isoWeekday() === 6 || moment(x.fecha).isoWeekday() === 7} texto={x.autoAjustar} key={i} />) : ""
                      }
                  </div>}
            {listaDeListaDePrecios.map((x,i)=>
              <React.Fragment key={i}>
                <div className="rna-grid__row js-onboarding-prices js-rna-bulk-form-row js-observe-visibility" data-render-id="rna-room-block" data-rate-id={1799663} data-occupancy={2} id="rate-status-55835201-1799663" data-form-type="rates">
                  <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">

                  <span className="rna-titlerna-title--expandable bui-link bui-link--primary" title="ID de tarifa: 1799663" data-on-click="toggleRateCategory('55835201','1799663',null,0,0)">
                    {x.name}
                    <span className="rna-title--expandable-icon">
                    <svg fill="#0077CC" size="medium" width={16} height={16} viewBox="0 0 128 128" className="bk-icon -iconset-navarrow_right"><path d="M54.3 96a4 4 0 0 1-2.8-6.8L76.7 64 51.5 38.8a4 4 0 0 1 5.7-5.6L88 64 57.2 94.8a4 4 0 0 1-2.9 1.2z" /></svg>
                  </span>


                  </span>
                
                  <span className="rna-title rna-title--secondary">

                      {/*<span className="rna-occupancy-badge"><svg className="bk-icon -material-ic_person rna-occupancy-badge__icon" size="medium" width={16} height={16} viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>
                        {x.name}</span>*/}
                
                  </span>
                  </div>
                  {
                    x.list?x.list.map((y,i)=>
                  
                    <Input setOpenPopup={setOpenPopup} setStartDrag={setStartDrag}  startDrag={startDrag} precioPopup={precioPopup} nombrePrecio={x.name} setPrecioPopup={setPrecioPopup} nombreChannel='Booking.com' usd={usd} key={i} precio={y.precio} fecha={y.fecha} roomId={y.roomtype_id} />):""
                  }
                            
                      </div>
                     
                </React.Fragment>
              )}
               <Popup 
                     trigger=
                    {
                      <div></div>
                    }
                  content={ChangePricesPopup({input,fechaEntrada:startDrag.fechaInicio,fechaSalida:startDrag.fechaFin,nombreChannel,nombrePrecio:startDrag.nombrePrecio?startDrag.nombrePrecio:'',precioPopup,setPrecioPopup,setOpenPopup,tipoPrecio:precioElegido.type,isDispo:startDrag.isDispo,isRepublicar:startDrag.isRepublicar,setStartDrag,startDrag})} 
                  open={openPopup}
                  position='bottom right'
                  offset={'0,1000px'}
                  />
                  </div>
                </div>
              </>
          );
      };
  

  export default Main
