import React,{useState,useEffect,useRef} from 'react'
import ReactTable from 'react-table-6'
import moment from 'moment'
import {ElegirLabel,CambiarEstadoCheckinReserva,ElegirTexto,CalcularDeuda} from '../../../Funciones/utils'
import {connect} from 'react-redux'
import {Label} from 'semantic-ui-react'
import Imprimir from './imprimir'
import DateRangePicker from '../extras/dateRangePicker';
import {Button,Icon} from 'semantic-ui-react'
import {Col} from 'reactstrap'
import {Collapse} from 'react-collapse';
import XLSX from 'xlsx';

//ACTIONS
import setReserva from '../../../redux/actions/setReserva'
import setModal from '../../../redux/actions/setModalReserva'
import { Menu} from 'semantic-ui-react'

let dniSearched=''
let nameSearched=''
const withCaja=(componente,dispatch,props)=><div className="test-WithCaja" style={{cursor:"pointer"}} onClick={()=>{
  dispatch(setModal(true));
  if(false){//props.original.state=='preingreso'){
    dispatch({type:'DUPLICATE_RESERVATION',payload:props.original})
  }
  else{
    dispatch(setReserva(props.original))}
  }
}>{componente}</div>

const TablaRegistro=({listaReservas : lista,dispatch,isMobile,withoutPlace,stateLabel,preReservationList,preReservation})=>{
  const [mostrarReservas,setMostrarReservas]=useState("alojados")
  const [tamanoPantalla,setTamanoPantalla]=useState(1000)
  const [forceReload,setForceReload]=useState(false)
  const [todasLasPersonas,setTodasLasPersonas]=useState(true)
  const [rangoFechasOpened,setRangoFechasOpened]=useState(false)
  const [fechaInicio,setFechaInicio]=useState(null)
  const [cantidadDias,setCantidadDias]=useState(null)
  const [listaSeleccionada,setListaSeleccionada]=useState([]);
  const [numberoLista,setNumeroLista]=useState({})
  const startRef=useRef();
  const endRef=useRef();
  useEffect(()=>{
    setTamanoPantalla(window.innerWidth-100)
  },[])

  useEffect(()=>{
    if (stateLabel!="") {
      setMostrarReservas(stateLabel)  
    }
},[stateLabel])

  useEffect(()=>{
    let listaAux=[]
    if (mostrarReservas=='fllegar') {
      listaAux=lista.sort((a, b) => moment(a.checkinEstimated).diff(moment(b.checkinEstimated)))
    }
    else{
      listaAux=lista.sort((a, b) => moment(b.created).diff(moment(a.created)))

    }
    const alojados=lista.filter(x=>x.state=='checkin');
    const adeudados=alojados.filter(x=>x.isDeudor);
    const fllegar=lista.filter(x=>x.state=='confirmada');
    const nopresentado=lista.filter(x=>x.state=='nopresentado');
    const imprimir=lista.filter(x=>x.state=="checkout");
    const abiertas=lista.filter(x=>x.state=="abierta");
    const sinlugar=lista.filter(x=>x.roomsList.length==0&&x.state=="checkin");
    setNumeroLista({
      alojados:alojados.length,
      fllegar:fllegar.length,
      sinlugar:sinlugar.length,
      abiertas:abiertas.length,
      imprimir:imprimir.length,
      nopresentado:nopresentado.length,
      adeudados:adeudados.length})
    switch (mostrarReservas) {
      case "alojados":
        listaAux=alojados;
        break;
      case "adeudados":
        listaAux=adeudados
        break;
      case "fllegar":
        listaAux=fllegar;
        break;
      case "imprimir":
        listaAux=imprimir;
        break;
      case "abiertas":
        listaAux=abiertas;
        break;
      case "sinlugar":
        listaAux=sinlugar;
        break;
      case "nopresentado":
        listaAux=nopresentado;
        break;
      default:
        listaAux=lista;
        break;
    }
    if (mostrarReservas=="imprimir"||mostrarReservas=="todas") {
      if (fechaInicio) {
        if( mostrarReservas=="imprimir"){
          listaAux=listaAux.filter(x=>
            moment(x.checkoutMade).format('YYYY-MM-DD')==moment().format('YYYY-MM-DD'))
        }
        else if(mostrarReservas=="todas"){
          listaAux=listaAux.filter(x=>{
          const listaDias=new Array(moment(x.checkoutEstimated).diff(x.checkinEstimated,"days")).fill(undefined).map((y,i)=>moment(x.checkinEstimated).add(i,'days').format('YYYY-MM-DD'));
          return   listaDias.filter(y=>moment(y).isBetween(moment(fechaInicio).format('YYYY-MM-DD'), 
          moment(fechaInicio).add(cantidadDias,'days'), null,"[]")).length!=0;
        })
       }
      }
    }
    setListaSeleccionada(mostrarReservas=='preingreso'?preReservationList:listaAux)
    },[mostrarReservas,lista,preReservationList])

    useEffect(()=>{
      if( mostrarReservas=="imprimir"){
        const listaAux=lista.filter(x=>x.state=='checkout').filter(x=>
          moment(x.checkoutMade).isBetween(moment(fechaInicio).format('YYYY-MM-DD'), 
          moment(fechaInicio).add(cantidadDias,'days').format('YYYY-MM-DD')),null,'[]')
          setListaSeleccionada(listaAux)
      }
    },[mostrarReservas,fechaInicio,cantidadDias])
  const timeFormat=isMobile?'DD/MM':'DD/MM/YYYY';
    var columns = [
        {
        Header: 'Creada',
        accessor: 'created',
        width:isMobile?80:130,
        sortMethod:(a, b, desc) => {
            if (moment(a).diff(moment(b),"minutes") > 0) {
              return 1
            }
            else{
                return -1
            }
          },
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).format("DD MMM hh:mm a").split('.').join(""),dispatch,props),
        filterable: false
          },
        {
        Header: isMobile?'Entrada':'Entrada',
        accessor: 'checkinEstimated',
        width:80,
        sortMethod:(a, b, desc) => {
            if (moment(a).diff(moment(b),"minutes") > 0) {
              return 1
            }
            else{
                return -1
            }
          
          },
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).format("DD MMM").split('.').join(""),dispatch,props),
        filterable: false
          },
        /*{
        Header: isMobile?'Ingreso':'Fecha Ingreso',
        accessor: 'checkinEstimated',
        
        sortMethod:(a, b, desc) => {
            if (moment(a).diff(moment(b),"minutes") > 0) {
              return 1
            }
            else{
                return -1
            }
          
          },
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).format(timeFormat).split('.').join(""),dispatch,props),
        filterable: false
          },*/{
        Header: isMobile?'Apellido':'Nombre y Apellido',
        id: 'fullName', 
        accessor: d => d.customersList, 
        Cell: (props,a,b) => {
          const values=props.value.map((x,i)=>withCaja(
          <div style={{textDecorationThickness:2,textDecorationColor:'#88b2ff',textDecorationLine:
          (nameSearched.length>1&&x.fullName?.toLowerCase().includes(nameSearched))
          ||(dniSearched.length>1&&x.dni?.toLowerCase().includes(dniSearched.toString()))?"underline":""}}>
            {isMobile?x.fullName.split(" ")[0]:x.fullName}{i==0&&props.original.description&&<Label key={i} size={'small'} style={{marginLeft:'5px'}} color='red'>1</Label>}</div>,dispatch,props))
          values[0]=values[0]
          if (todasLasPersonas) {
            return values
          }
          else{
            return values[0]}
          }
          //withCaja(isMobile?props.value.split(" ")[0]:props.value,dispatch,props)}
          ,
        filterMethod: (filter, rows) =>{
          nameSearched=filter.value.toLowerCase()
          if (todasLasPersonas) {
           return rows.filter(x=>x._original.customersList.find(y=>y.fullName.toLowerCase().includes(filter.value.toLowerCase())))
          }
          else{
           return  rows.filter(x=>x._original.customersList[0].fullName.toLowerCase().includes(filter.value.toLowerCase()))
          }
         
        },
        filterAll: true// String-based value accessors!
        }, 
        {
            Header: 'Noches',
            accessor: 'nights',
            width:isMobile?70:80,
            Cell: props => withCaja(props.value,dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.nights==filter.value),
            filterAll: true// String-based value accessors!
            }, 
        {
            Header: 'Estado',
            accessor: 'state',
            width:110,
            Cell: props => withCaja(ElegirLabel(props.value,props.original.nightsDue),dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.state.toLowerCase().includes(filter.value.toLowerCase())),
            filterAll: true// String-based value accessors!
            }, 
        {
            Header: 'Pagado',
            accessor: 'percentPayed',
            width:isMobile?70:100,
            Cell: props => withCaja(props.value+"%",dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.percentPayed==filter.value),
            filterAll: true// String-based value accessors!
            }, 
        {
          Header: 'DNI/PAS',
          id: 'dni', 
          accessor: d => d.customersList, 
          Cell: props => {
            const values=props.value.map(x=>withCaja(x.dni,dispatch,props))
            if (todasLasPersonas) {
              return values
            }
            else{
              return values[0]}
            },
          filterMethod: (filter, rows) =>
          {
            dniSearched=filter.value.toLowerCase()
            if (todasLasPersonas) {
             return rows.filter(x=>x._original.customersList.find(y=>y.dni&&y.dni.toLowerCase().includes(filter.value.toLowerCase())))
            }
            else{
             return  rows.filter(x=>x._original.customersList[0].dni&&x._original.customersList[0].dni.toLowerCase().includes(filter.value.toLowerCase()))
            }
           
          },
          filterAll: true// String-based value accessors!
        },
        {
        Header: 'Vehiculos',
        Cell: props => props.value.length>0?props.value.map(x=>( withCaja(`${x.brand??''} ${x.plate??''} ${x.color??''}`,dispatch,props))).map(x=><p>{x}</p>):"-",
        accessor: 'vehiclesList',
        filterMethod: (filter, rows) =>
        rows.filter(x=>(
          x.vehiclesList.map(y=>(y.plate)).toString().toLowerCase().includes(filter.value.toLowerCase())||
          x.vehiclesList.map(y=>(y.brand)).toString().toLowerCase().includes(filter.value.toLowerCase())||
          x.vehiclesList.map(y=>(y.color??'')).toString().toLowerCase().includes(filter.value.toLowerCase()))),
        filterAll: true,
          },
        {
        Header: 'Lugar',
        width:100,
        accessor: 'roomsList',
        Cell: props => withCaja(props.value.length==0?'-':props.value.map(x=>x.number).join(' '),dispatch,props),
        filterMethod: (filter, rows) =>
        rows.filter(x=>x.roomsList.map(x=>x.number).join(' ').toLowerCase().includes(filter.value.toLowerCase())),
        filterAll: true,
          },
        {
        Header: 'Salida',
        accessor: 'checkoutEstimated',
        Cell: props => withCaja(isMobile?moment(props.value).add(3,"hours").format('DD/MM HH:mm'):moment(props.value).add(3,"hours").format('dddd DD MMM hh:mm a'),dispatch,props),
        filterable: false
        }
      ] 

      if (mostrarReservas=='alojados'||
        mostrarReservas=='sinlugar'||
        mostrarReservas=='adeudados') {
        columns=[...columns,
          {
            Header: 'Checkout',
            accessor: '',
            width:100,
            Cell: props =><Button 
            className='checkoutButton'
            onClick={()=>{
              //dispatch(setCheckout(props.original.id))
              if(window.confirm("¿Confirmar checkout?")){
                CambiarEstadoCheckinReserva(props.original.reservationId,'checkout',(loading)=>{dispatch({type:"MAIN_LOADING",payload:loading})},dispatch)}
              }}><Icon style={{marginLeft:"5px"}} name='sign-out'/></Button>,
            filterable: false
            }]
      }
  

    return(
      <React.Fragment>
  
      <Menu pointing={!isMobile} stackable secondary={!isMobile}>
      <Menu.Item
        style={{justifyContent:'space-between'}}
        active={mostrarReservas === 'adeudados'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("adeudados")}}
      >
        {`Adeudados`}
        <Label color='yellow'>{numberoLista.adeudados}</Label>
        
      </Menu.Item>
      {withoutPlace&&<Menu.Item
      style={{justifyContent:'space-between'}}
        active={mostrarReservas === 'sinlugar'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("sinlugar")}}
      >
        {`Sin Lugar`}
        <Label >{numberoLista.sinlugar}</Label>
      </Menu.Item>}

      <Menu.Item
      style={{justifyContent:'space-between'}}
        name={`Alojados (${numberoLista.alojados})`}
        active={mostrarReservas === 'alojados'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("alojados")}}
      >
        {`Alojados`}
        <Label color='green'>{numberoLista.alojados}</Label>
      </Menu.Item>
      <Menu.Item
      style={{justifyContent:'space-between'}}
        active={mostrarReservas === 'fllegar'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("fllegar")}}
        >
        {`Reservas`}
        <Label color='blue'>{numberoLista.fllegar}</Label>
      </Menu.Item>
      <Menu.Item
      style={{justifyContent:'space-between'}}
        active={mostrarReservas === 'abiertas'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("abiertas")}}
        >
        {`Abiertas`}
        <Label color='purple'>{numberoLista.abiertas}</Label>
      </Menu.Item>
      <Menu.Item
      style={{justifyContent:'space-between'}}
        active={mostrarReservas === 'nopresentado'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("nopresentado")}}
        >
        {`No presentado`}
        <Label color='orange'>{numberoLista.nopresentado}</Label>
      </Menu.Item>

      {preReservation&&<Menu.Item
      style={{justifyContent:'space-between'}}
        active={mostrarReservas === 'preingreso'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("preingreso")}}
      >
        {`Pre Ingresos`}
        <Label color={'pink'}>{preReservationList.filter(x=>moment().isSame(x.created, 'day')).length}</Label>
      </Menu.Item>}

      <Menu.Item
        name='Egresos'
        active={mostrarReservas === 'imprimir'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("imprimir")}}
      />

      <Menu.Item
        name='Todos'
        active={mostrarReservas === 'todas'}
        onClick={()=>{setRangoFechasOpened(false);setFechaInicio(""); setMostrarReservas("todas")}}
      />

      <Menu.Item
        className={"custombutton"}
        style={!isMobile?{ borderRadius: "5% 5% 0 0%",fontSize:'16x'}:{fontSize:'17px'}}
        position='right'
        name={todasLasPersonas?'Personas':'Registros'}
        active={todasLasPersonas}
        onClick={()=>{setTodasLasPersonas(!todasLasPersonas)}}
      />
      
      {/*Exportar a excel con icono de excel*/}
      <Menu.Item
        className={"descargarListado"}
        style={!isMobile?{ borderRadius: "5% 5% 0 0%",fontSize:'16x'}:{fontSize:'17px'}}
        content={<Icon name='download'/>}
        onClick={()=>{
          //EXPORT listaSeleccionada TO EXCEL
          if(listaSeleccionada.length>0){
            let data=listaSeleccionada.map(x=>{
              //Nombre solo toma las primeras dos palabras
              return {
              'Nombre':x.customersList[0].fullName.split(' ').length>1?`${x.customersList[0].fullName.split(' ')[0]} ${x.customersList[0].fullName.split(' ')[1]} ${x.customersList.length!=1?`(+${x.customersList.length-1})`:``}`:x.customersList[0].fullName,
              'Estado':`${ElegirTexto(x.state,x.nightsDue)} ${x.nightsDue>0?`$${CalcularDeuda(x)}`:''}`,
              'Entrada':moment(x.checkinEstimated).format('DD/MM/YYYY'),
              'Vence':moment(x.checkoutEstimated).format('DD/MM/YYYY'),
              'Noches':x.nights,
              'Vehiculos':x.vehiclesList.length>0?x.vehiclesList.reduce((a,y)=>a+'\n'+`${y.brand??''} ${y.plate??''} ${y.color??''}`,''):'',
              'Lugares':x.roomsList.map(y=>y.number).join('\n')??"-",
              }
            })
            //sort data by Lugares
            data=data.sort((a,b)=>{
              if(a.Lugares<b.Lugares) return -1;
              if(a.Lugares>b.Lugares) return 1;
              return 0;
            })
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
            XLSX.writeFile(wb, `${mostrarReservas} ${moment().format('DD/MM')}.xlsx`);
            
          }
        }}
      />

    </Menu>
    {(false)&&<Button onClick={()=>{setRangoFechasOpened(!rangoFechasOpened)}}>Rango de fechas</Button>}
    <br></br>
    
    <br></br>
    {rangoFechasOpened&&<Collapse isOpened={rangoFechasOpened}>
        <Col xs={12}>
          <DateRangePicker startRef={startRef} endRef={endRef}/>
          <Button basic color='blue' size={"big"} onClick={()=>{
            const inicioFixed=startRef.current.value.split("/").reverse().join("-")
            const salidaFixed=endRef.current.value.split("/").reverse().join("-")
            setFechaInicio(inicioFixed);
            setCantidadDias(moment(salidaFixed).diff(moment(inicioFixed),"days"))}}>
            Buscar
          </Button>
        </Col>
    </Collapse>}
    {mostrarReservas!="imprimir"&&<ReactTable 
    onFilteredChange={(a,b,c)=>{
      if (c.length==0) {
         nameSearched='';
        dniSearched='';
        setForceReload(!forceReload)

      }
    }
    }
    noDataText={'No se encontraron registros'} className="-striped -highlight" filterable loadingText={"Cargando..."} loading={false} columns={columns} data={listaSeleccionada}></ReactTable>}
    {mostrarReservas=="imprimir"&&<Imprimir/>}
    </React.Fragment>
    )
  }

export default connect(state=>({preReservation:state.mainReducer.preReservation,preReservationList:state.mainReducer.preReservationList,withoutPlace:state.mainReducer.withoutPlace,isMobile:state.mainReducer.isMobile}),null)(TablaRegistro)

