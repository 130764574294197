import React from 'react';
import MaterialTable from 'material-table';
import {connect} from 'react-redux'
import {ActualizarVehiculos} from '../../../../Funciones/utils'
import moment from 'moment';

const CorroborarData=(data)=>{
    if (
    !data.brand||
    !data.plate||
    data.brand.trim()==""||
    data.plate.trim()==""){
      alert("Faltan completar campos")
      
      return false;
    }
    else{
        return true;
    }
}
    {/*
 */}
 const MaterialTableDemo= ({dispatchHook,data,setNombre,setEmail,setEdad,setTelefono,listaPersonas,setDni,setListaPersonas,setCustomerId,setArgolia,setListIndex})=> {
   const columns= [
      { title: 'Nombre y apellido', field: 'fullName' },
      { title: 'Documento de identidad', field: 'dni' },
      { title: 'Whatsapp', field: 'phone',render:rowData=>rowData.phone?(
        <a href={"https://wa.me/54"+rowData.phone+`?text=Hola ${rowData.fullName.split(' ')[0]}, `} target="_blank" rel="noopener noreferrer">
        <img src={require("../../../../assets/WhatsApp_icon.png")} alt="whatsapp" width="30" height="30"/>
        </a>
    ):<div></div>}    /*  { title: 'Email', field: 'email' },
   { title: 'Telefono', field: 'phone' }*/]

  return (
    <MaterialTable
      title="Personas"
      columns={columns}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Editar',
          onClick: (event, rowData) => {
            console.log(event,rowData)
            dispatchHook({type:"SELECT_CUSTOMER",payload:rowData.uniqueId})
          }
        },
        {
          icon: 'delete',
          tooltip: 'Eliminar',
          onClick: (event, rowData) => {
            dispatchHook({type:"REMOVE_CUSTOMER",payload:rowData.uniqueId})          }
        },
      ]}
      data={data}
      style={{textAlign:"right !important",padding:'10px'}}
      localization={{
        body:{
        emptyDataSourceMessage:"No se cargaron personas"},
        header:{actions:"Acciones"}}}
      options={{
        showTitle:false,
        paging:false,
        search: false,
        sorting:false,
        draggable:false
      }}
    />
  );
}

export default connect(state=>({}),null)(MaterialTableDemo)

