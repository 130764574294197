import React,{useState,useEffect,useMemo} from 'react'
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';

const Reportes=()=>{
  const userId=useSelector(state=>state.mainReducer.campingUser)
  return(
    <React.Fragment>
      <iframe 
      src={`https://datastudio.google.com/embed/u/0/reporting/e157771d-93ef-452a-830e-3d5d254e506c/page/p_aj8rw8ijsc?params=%7B%22payments.userId%22:%22${userId}%22,%22reservations.userId%22:%22${userId}%22%7D`}
      width="100%"
      height={'1300px'}
      frameBorder="0"
      allowFullScreen={true}
      title="Reporte"
      style={{"border":"none"}}
      >
      </iframe>
    </React.Fragment>
  )
}


export default Reportes
